:root {
--navbar-height: 70px;
--main-font: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

* {
box-sizing: border-box;
font-family: var(--main-font);
}


html, body, #root {
height: 100%;
width: 100%;
margin: 0;
padding: 0;
}

body {
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

#root {
min-height: 100%;
display: flex;
flex-direction: column;
}

.layout {
display: flex;
flex-direction: column;
min-height: 100%;
}

.main-content {
padding-top: var(--navbar-height);
flex: 1 0 auto;
display: flex;
flex-direction: column;
overflow: auto;
position: relative;
}

.content-wrapper {
flex: 1;
display: flex;
flex-direction: column;
overflow-y: auto;
position: relative;
min-height: 380px;
}

.content-wrapper > * {
flex: 0 0 auto; 
}

.content-wrapper > *:last-child {
flex: 1 0 auto;
}

.content-wrapper > *:first-child {
margin-top: 0;
padding-top: 0;
}

/*

Navbar

*/
nav {
height: var(--navbar-height);
background-color: white;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 20px;
border-bottom: 1px solid #e0e0e0;
position: fixed;
top: 0;
left: 0;
right: 0;
z-index: 1000;
}

nav img {
width: 90px;
}

.signup-button-nav, .add-service-button {
display: none; 
}

nav ul {
display: flex;
align-items: center;
margin: 0;
padding: 0;
list-style-type: none;
}

nav ul li {
margin: 0 10px;
position: relative;

}

.icon-button, .profile-menu a, .profile-menu button, .mobile-menu a, .mobile-menu button, .head {
font-size: 18px;
color: black;
text-decoration: none;
background: none;
border: none;
cursor: pointer;
padding: 5px;
text-align: left;
width: 100%;
}

.head:hover, .icon-button:hover, .profile-menu a:hover, .profile-menu button:hover {
text-decoration: underline;
}

.profile-menu-container {
position: relative;
z-index: 1001;
}

.profile-menu {
position: absolute;
top: 100%;
right: 0;
background-color: white;
border: 1px solid #ccc;
border-radius: 4px;
padding: 10px;
display: flex;
flex-direction: column;
align-items: flex-start;
min-width: 200px;
z-index: 1000;
box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.icon-button {
display: flex;
align-items: center;
gap: 2px;
}

.profile-notification, .message-notification, .mobile-notification {
background-color: red;
border-radius: 50%;
color: white;
}

.profile-notification {
position: absolute;
top: -2px;
right: -2px;
width: 9px;
height: 9px;
}

.message-notification {
display: inline-block;
width: 20px;
height: 20px;
line-height: 20px;
text-align: center;
font-size: 15px;
margin-left: 8px;
}

.profile-menu button:last-child {
position: relative;
padding-top: 10px;
margin-top: 5px;
color: red;
font-weight: bold;
}

.profile-menu button:last-child::before {
content: '';
position: absolute;
top: 0;
left: -10px;
right: -10px;
height: 1px;
background-color: black;
}

.login a {
background-color: green;
color: white;
padding: 7px 15px;
border-radius: 13px;
display: inline-block;
text-decoration: none;
}  

.login a:hover {
text-decoration: none;
}

.hamburger-menu {
display: none;
cursor: pointer;
}

.mobile-menu {
display: none;
}

@media (max-width: 768px) {
.nav {
padding: 0 10px;
}

.nav img{
margin-top: 3px; 
margin-left: -10px;
}

.right-side {
display: flex;
align-items: center;
}

.signup-button-nav, .add-service-button {
display: inline-block;
color: royalblue;
padding: 10px;
border-radius: 4px;
text-decoration: none;
margin-right: 10px;
font-weight: 600;
}


.hamburger-menu-container {
display: block;
cursor: pointer;
position: relative;
}

.hamburger-menu {
display: block;
cursor: pointer;
}

nav ul.desktop-menu {
display: none;
}

.mobile-menu {
display: flex;
position: fixed;
top: 70px; 
left: 0;
right: 0;
bottom: 0;
background-color: #f0f0f0;
flex-direction: column;
justify-content: flex-start;
align-items: center;
z-index: 999;
padding-top: 40px;
overflow-y: auto;
}

.mobile-menu ul {
list-style-type: none;
padding: 0;
width: 100%;
text-align: center;
display: flex;
flex-direction: column;
}

.mobile-menu li {
margin: 15px 0;
}

.mobile-menu .profile-menu {
position: static;
box-shadow: none;
border: none;
padding: 0;
width: 100%;
background-color: transparent; 
}

.mobile-menu .profile-menu a, 
.mobile-menu .profile-menu button,
.mobile-menu a, 
.mobile-menu button {
font-size: 20px;
text-align: center;
padding: 10px 0;
background-color: transparent; 
}

.mobile-menu .login a {
color: blue;
}

.mobile-menu .icon-button {
display: none;
}

.mobile-menu .profile-menu button:last-child::before {
display: none;
}

.mobile-notification {
position: absolute;
top: 0;
right: 0;
width: 10px;
height: 10px;
background-color: red;
border-radius: 50%;
}
}

/*

Home

*/
.main-content-hero {
flex: 1;
display: flex;
flex-direction: column;
overflow: hidden;
width: 100%;
margin-top: calc(-1 * var(--navbar-height)); 
}

.container-hero {
display: flex;
justify-content: center;
align-items: center;
height: 60vh;
text-align: center;
}

#hero {
position: relative;
width: 100%;
overflow: hidden;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
height: 60vh;
}

#hero::before {
content: '';
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: papayawhip;
opacity: 0.8;
z-index: 1;
}

#hero img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
position: absolute;
top: 0;
left: 0;
}

#hero h1, #hero p {
z-index: 5;

position: relative;
color: #1E3A5F;
}

#hero h1 {
font-size: 2.5rem;
font-weight: 600;
margin-bottom: 20px;
}

#hero p {
display: inline;
font-size: 1.5em;
color: #606060;
text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.9); 
margin: 0 0;
font-style: italic;
}

#hero button {
margin-top: 30px;
background-color: rgb(255, 33, 56);
color: rgb(255, 255, 255);
padding: 10px 20px;
border-radius: 10px;
width: auto;
min-width: 160px;
height: 40px;
font-weight: bold;
font-size: 1rem;
border: none;
cursor: pointer;
z-index: 5;
}

.header-row {
display: flex;
align-items: center;
justify-content: space-between; 
width: 100%; 
}
  
.services-carousel {
padding: 1.5rem;
background-color: #f5f5f5;
}

.latest-category-header h3{
display: flex;
font-size: 1.3rem;
margin: 0 0 0.7rem 0;
font-weight: 600;
padding: 0;
}

.carousel-container {
display: flex;
gap: 0.5rem;
flex-wrap: wrap;
justify-content: space-between;
}

.category-icon-home {
padding-bottom: 0.1rem;
margin-right: 0.2rem;
vertical-align: middle;
}

.category-icon {
margin-right: 0.4rem;
padding-bottom: 0.2rem;
vertical-align: middle;
}

.carousel-item {
flex: 1 1 auto;
background-color: #fff;
padding: 0.5rem 0.3rem;
border: 1px solid #ddd;
font-size: 1.1rem;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
text-align: center;
cursor: pointer;
transition: background-color 0.3s;
}

.carousel-item:hover {
background-color: #e0f7fa;
}

.carousel-item:active {
background-color: #b2ebf2;
}

.learn-more {
text-align: right;
margin-right: 0.5rem;
margin-bottom: 0.7rem;
}
  
.subh-container {
display: flex;
flex-direction: column;
margin: 0;
padding: 0;
}

.subh-column {
position: relative;
box-sizing: border-box;
margin: 0px;
padding: 0;
width: 100%;
display: flex;
z-index: 1;
overflow: hidden; 
}

.subh-column h3{
margin: 10px;
padding: 0 20px;
font-size: 1.3rem;
}

.subh-column p{
padding: 0 5rem;
font-size: 1.1rem;
}

.split-0, .split-1 {
display: flex;
width: 100%;
background-color: #f0f0f0; 
}

.subh-text, .subh-image {
flex: 1;
width: 50%; 
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
}

.subh-column, .subh-image {
margin: 0;
padding: 0;
}

.subh-text {
flex-direction: column;
}

.subh-image {
display: flex;
align-items: center;
justify-content: center;
padding: 0;
margin: 0;
border: 0;
min-height: 0;
position: relative;
}

.subh-image img {
display: block;
margin: 0;
padding: 0;
border: 0;
width: 100%;
height: auto;
}

.latest-listings-container {
padding: 20px 10px 0 10px;
background-color: rgb(255, 255, 255);
}

.latest-listings-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
}

.latest-listings-header h3 {
margin: 0;
font-size: 1.3rem;
font-weight: 600;
padding: 0;
}

.browse-more-link a {
font-size: 1.1rem;
color: #007bff;
text-decoration: none;
text-align: right;
}

.browse-more-link {
text-align: right;
margin: 10px 20px;
}

.browse-more-link a:hover {
text-decoration: underline;
}

.latest-listings.row-layout,
.latest-listings.column-layout {
display: grid;
grid-template-columns: repeat(4, 1fr);
gap: 20px;
padding: 0 10px;
}

.latest-listings .product-card {
width: 100%;
margin: 0;
border: 1px solid #ddd;
border-radius: 8px;
overflow: hidden;
background-color: white;
}

.latest-listings .service-image-container {
width: 100%;
position: relative;
padding-top: 60%; 
}

.latest-listings .service-image-container img {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
}

.listing-card {
display: none;
}

.how-to-use {
padding: 1rem;
background-color: #f9f9f9;
text-align: center;
}

.how-to-use h2 {
margin-bottom: 1.5rem;
}

.how-to-steps-container {
display: flex;
gap: 1rem;
justify-content: space-between;
margin: 0 10rem;
}

.how-to-step {
max-width: 300px;
flex: 1;
text-align: center;
}

.how-to-step img {
width: 90%;
height: auto;
margin-bottom: 1rem;
border-radius: 50%;
}

.how-to-step h3 {
margin-bottom: 0.5rem;
font-weight: 600;
font-size: 1.3rem;
}

.how-to-step p {
font-size: 1.1rem;
color: #555;
}
  

@media (max-width: 768px) {
.container-hero {
text-align: center;
justify-content: flex-start;
}

#hero h1 {
font-size: 1.8rem;
padding: 0px 20px;
margin: 10px 0;
text-align: left;
width: 90%;
font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#hero {
align-items: flex-start;
padding-left: 10px;
}

#hero p {
font-size: 1rem !important;
padding: 0px 20px;
text-align: left;
margin-bottom: 5px;
}

#hero button {
margin: 10px;
}

.services-carousel {
padding: 1rem 1rem;
background-color: #ffffff;
}

.latest-category-header h3{
display: flex;
margin: 0 0.4rem 0.8rem 0;
}

.carousel-item {
flex: 1 1 auto;
padding: 0.4rem 0.3rem;
border-radius: 10px;
}

.subh-container {
height: auto;
margin: 0;
padding: 0px;
}

.subh-column {
height: auto;
flex-direction: column;
}

.subh-column p{
padding: 0 1rem;
}

.subh-column h3 {
margin: 0 10px;
padding: 0;
font-size: 23px;
}

.split-0, .split-1 {
flex-direction: column;
}

.split-0{
flex-direction: column-reverse; 
}

.subh-text, .subh-image {
width: 100%;
margin: 0;
padding: 0;
}

.subh-text {
padding: 10px;
}

.subh-image {
height: 200px;
line-height: 0; 
}

.subh-image img {
display: block; 
width: 100%;
height: 100%;
object-fit: cover;
}

.container {
height: auto; 
}

.latest-listings,
.latest-listings.row-layout,
.latest-listings.column-layout {
grid-template-columns: 1fr;
gap: 10px;
margin: 0;
}

.latest-listings-container {
padding: 5px;
margin-top: 10px;
}

.latest-listings-header {
margin: 0 0 10px 10px;
}

.latest-listings .service-image-container {
width: 100%;
position: relative;
padding-top: 55%; 
}

.how-to-steps-container {
flex-direction: column;
align-items: center;
margin: 0;
}

.how-to-step img {
width: 70%; 
max-width: 180px; 
height: auto; 
margin-bottom: 0;
}

.how-to-use {
padding: 0.5rem;
}
}


/*

Footer

*/
.quic-footer {
background-color:#ff001aff;
color: white;
padding: 20px 0;
font-family: Helvetica, sans-serif, sans-serif;
--main-font: Helvetica, sans-serif, sans-serif;
}

.quic-footer-content {
display: flex;
justify-content: space-between;
align-items: flex-start;
max-width: 1200px;
margin: 0 auto;
padding: 0 20px;
font-family: Arial !important;
}

.quic-footer-main {
flex: 1;
}

.quic-footer-main h3 {
font-size: 30px;
margin: 0;
}

.quic-footer-main span {
font-size: 15px;
}

.quic-footer-links {
display: flex;
justify-content: space-between;
flex: 2;
}

.quic-footer-column {
margin-left: 20px;
}

.quic-footer-column h6 {
font-size: 22px;
margin: 0 0 10px 0;
}

.quic-footer-column ul {
list-style: none;
padding: 0;
margin: 0;
}

.quic-footer-column li {
margin: 5px 0;
}

.quic-footer-column a {
color: white;
text-decoration: none;
}

.quic-footer-bottom {
text-align: center;
margin: 0;
font-size: 12px;
}

.footer-section-header {
display: flex;
align-items: center;
cursor: pointer;
}

.chevron-icon {
    display: none;
}

.rotated {
transform: rotate(180deg);
}

@media (max-width: 768px) {
.quic-footer {
padding: 0;
flex-wrap: wrap;
}

.quic-footer-column ul {
display: none;
}

.quic-footer-column ul[style*="display: block"] {
display: block;
}

.quic-footer-main h3 {
display: none;
margin: 0;
}

.quic-footer-main span {
display: none;
}

.quic-footer-column h6 {
font-size: 1.1rem;
}

.quic-footer-content {
flex-direction: column;
text-align: center;
}

.quic-footer-links {
flex-direction: column;
margin-top: 10px;
min-height: auto;
}

.quic-footer-column {
margin-left: 0;
margin-top: 10px;
text-align: left;
}

.chevron-icon {
margin-left: 5px;
transition: transform 0.3s ease;
display: inline;
}
}

@media (min-width: 769px) {
.quic-footer-column ul {
display: block !important;
}

.chevron-icon {
display: none;
}
}
/* 

Login / Signup / forgot password

*/
.signup-page, .login-page, .forgot-password-page, .user-profile-page {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 90vh;
background: linear-gradient(to right, #ffefd5, #ffdead);
}

.welcome-text {
text-align: center;
margin-bottom: 20px;
}

.signup-container, .login-container, .forgot-container, .user-profile-container {
max-width: 400px;
width: 100%;
min-height: 300px;
padding: 20px;
border: 1px solid #ccc;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
background-color: white;
display: flex;
flex-direction: column;
justify-content: center; 
position: relative;
}

.signup-container h3, .login-container h3, .forgot-container h3, .user-profile-container h3 {
text-align: center;
margin-bottom: 20px;
}

.signup-container input, .login-container input, .forgot-container input, .user-profile-container input {
width: 100%;
padding: 8px;
box-sizing: border-box;
border: 1px solid #ccc;
margin: 5px auto;
border-radius: 8px; 
font-size: 1.1rem;
}

.signup-container .error, .login-container .error, .forgot-container .error, .user-profile-container .error {
color: red;
padding: 15px 0;
text-align: center;
height: 10px;
}

.signup-button, .login-button, .reset-button, .submit-profile-button {
width: 350px; 
padding: 10px;
background-color: green;
color: white;
border: none;
border-radius: 3px;
cursor: pointer;
font-size: 16px;
margin: 15px auto;
display: block;
}

.signup-button:hover, .login-button:hover {
background-color: royalblue;
}

.terms-text p {
margin: 5px auto;
}

.warning {
color: red;
font-size: 12px;
}

h5.signup-link {
text-align: center;
margin-top: 20px;
margin-bottom: 0;
font-size: 1.1rem;
font-weight: 600;
}

h5.signup-link a {
color: rgb(225, 65, 65);
text-decoration: none;
margin-left: 5px;
display: inline-block; 
padding: 5px 10px; 
font-weight: 700;
}

h5.signup-link a:hover {
text-decoration: underline;
}

.password-requirements {
margin-top: 8px;
font-size: 0.9rem;
background-color: #dadada;
}

.password-requirements p {
color: red;
margin: 4px 0;
}

.signup-success-message {
color: #28a745;
padding: 10px;
border-radius: 4px;
background-color: #d4edda;
border: 1px solid #c3e6cb;
margin-bottom: 15px;
}

.signup-error-message {
color: #dc3545;
padding: 10px;
border-radius: 4px;
background-color: #f8d7da;
border: 1px solid #f5c6cb;
margin-bottom: 15px;
}

.signup-warning {
color: #dc3545;
font-size: 0.9rem;
margin-top: 4px;
}

.signup-button:disabled {
background-color: #cccccc;
cursor: not-allowed;
}

/* 

Welcome

*/
body.welcome-body::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-size: cover;
background-position: center;
opacity: 0.5;
z-index: -1; 
}

.welcome-page {
text-align: center;
padding: 20px;
height: 100%; 
display: flex;
flex-direction: column;
justify-content: center; 
background: linear-gradient(to bottom, papayawhip, #ffdcb2, #ffb380);
color: #333333; 
}

.welcome-step {
margin-bottom: 20px;
flex-grow: 1; 
display: flex;
font-size: 1.25rem;
font-weight: 600;
flex-direction: column;
justify-content: center; 
}

.welcome-step p {
font-weight: 500;
}

.welcome-step h2 {
font-size: 1.3rem;
}

.welcome-navigation {
display: flex;
justify-content: space-between; 
align-items: center;
margin-top: auto;
margin-bottom: 1rem;
}

.welcome-underline-button {
background: none;
border: none;
color: rgb(255, 77, 0); 
cursor: pointer;
font-size: 1.2rem;
text-decoration: underline;
padding: 10px;
}

.welcome-underline-button.next::after {
content: " →"; 
}

.welcome-underline-button.back::after {
content: ""; 
}

.welcome-step-indicator {
display: flex;
justify-content: center; 
}

.welcome-step-indicator span {
display: inline-block;
width: 10px;
height: 10px;
margin: 0 5px;
background-color: white; 
border-radius: 50%;
opacity: 0.5; 
}

.welcome-step-indicator span.active {
background-color: white; 
opacity: 1; 
}

/*

How it works

*/
.how-it-works-container {
max-width: 800px;
margin: 0 auto;
padding: 0 20px 10px 20px;
background-color: #fff;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
color: #333;
}

.how-it-works-image-top {
width: 100%;
height: 300px;
object-fit: cover; 
overflow: hidden;
}

.intro-section {
position: relative;
}

.intro-content-overlay {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 20px; 
color: white;
}

.intro-content-overlay h2 {
font-size: 2rem;
margin-bottom: 10px;
color: #fff;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.intro-content-overlay p {
font-size: 1.1rem;
margin-bottom: 20px;
color: #fff;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.how-it-works-section {
margin-bottom: 30px;
}

.how-it-works-section h3 {
font-size: 1.4rem;
margin-bottom: 15px;
color: #f02323;
}

.step h4 {
font-size: 1.2rem;
margin:  0;
}

.how-it-works-section p {
font-size: 1rem;
line-height: 1.6;
margin: 5px 0 10px 0;
}

.how-it-works-image {
width: 100%;
height: auto;
margin: 5px 0;
border-radius: 5px;
}

.intro-section p {
font-size: 1.2rem;
margin-bottom: 1rem;
}

.how-it-works-section a {
color: royalblue;
font-weight: bold;
text-decoration: underline;
}

.how-it-works-section a:hover {
text-decoration: underline;
}

.how-it-works-button {
display: inline-block;
padding: 10px 20px;
margin-top:10px;
background-color: #0050b3;
color: white !important;
text-align: center;
border-radius: 14px;
text-decoration: none !important;
font-weight: bold;
transition: background-color 0.3s ease;
}

.how-it-works-button1 {
display: inline-block;
padding: 10px 20px;
background-color: #ff0000;
color: white !important;
text-align: center;
border-radius: 14px;
text-decoration: none !important;
font-weight: bold;
transition: background-color 0.3s ease;
}

.how-it-works-button:hover {
background-color: #0040a0;
}

.how-to-button-container {
display: flex;
justify-content: center; 
margin-top: 10px;
}

/*

Community guidelines

*/
.community-standards-container {
max-width: 800px;
margin: 0 auto;
padding: 20px;
background-color: #fff;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
color: #333;
}

.community-standards-container h1 {
font-size: 2.5rem;
margin-bottom: 20px;
color: #0050b3; 
text-align: center;
}

.community-standards-container h2 {
font-size: 1.75rem;
margin-top: 30px;
margin-bottom: 15px;
color: #333;
}

.community-standards-container p {
font-size: 1rem;
line-height: 1.6;
margin-bottom: 15px;
}

.community-standards-container .intro-text {
font-size: 1.2rem;
margin-bottom: 30px;
text-align: center;
}

.community-standards-container a {
color: #ff4500; 
text-decoration: none;
font-weight: bold;
}

.community-standards-container a:hover {
text-decoration: underline;
}

@media (max-width: 768px) {
.community-standards-container {
padding: 15px;
}

.community-standards-container h1 {
font-size: 2rem;
}

.community-standards-container h2 {
font-size: 1.5rem;
}

.community-standards-container p {
font-size: 0.95rem;
}
}

/*

About 

*/
.about-container,
.vision-container {
max-width: 800px;
margin: 0 auto;
padding: 1rem 5rem;
background-color: #fff;
border-radius: 8px;
background-color: papayawhip;
}

.about-container h1 {
font-size: 2.5rem;
margin-bottom: 20px;
font-weight: 600;
color: #ff4500;
text-align: center;
}

.about-container h2,
.vision-container h2 {
font-size: 1.4rem;
font-weight: 600;
margin-top: 30px;
color: #333;
}

.vision-container h2 {
font-size: 1.4rem;
margin-top: 30px;
color: #ff4500;
}

.about-container h3 {
font-size: 1.3rem;
margin-top: 30px;
color: #333;
font-weight: 600;
}

.about-container p,
.vision-container p{
font-size: 1.1rem;
line-height: 1.5;
margin-bottom: 15px;
}

.link-to-vision-mission {
display: inline-block;
padding: 10px 15px;
color: #fff;
text-decoration: none;
font-weight: bold;
}

.about-container a {
color: #ff4500;
text-decoration: none;
font-weight: bold;
}

.about-container a:hover {
text-decoration: underline;
}

@media (max-width: 768px) {
.about-container {
padding: 1.5rem;
}

.about-container h1 {
font-size: 2rem;
}

.about-container h2 {
font-size: 1.75rem;
}

.about-container h3 {
font-size: 1.25rem;
}

.link-to-vision-mission {
padding: 8px 12px;
font-size: 0.9rem;
}
}

/*

Feedback

*/
.feedback-page-unique {
width: 100%;
max-width: 1000px;
margin: 0 auto;
padding: 20px;
background-color: #f9f9f9;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
box-sizing: border-box; 
}

.feedback-title-unique {
text-align: center;
color: #333;
margin-bottom: 20px;
}

.feedback-form-unique {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
box-sizing: border-box;
}

.feedback-form-group-unique {
margin-bottom: 15px;
width: 100%;
font-size: 1.1rem;
margin: 10px;
}

.feedback-form-label-unique {
display: block;
margin-bottom: 5px;
color: #333;
font-weight: 600;
}

.feedback-form-input-unique,
.feedback-form-textarea-unique {
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 1rem;
box-sizing: border-box; 
}

.feedback-form-input-unique:disabled,
.feedback-form-textarea-unique:disabled {
background-color: #e9ecef;
cursor: not-allowed;
}

.feedback-form-textarea-unique {
min-height: 322px;
resize: none;
}

.feedback-checkbox-label-unique {
display: flex;
align-items: center;
}

.feedback-form-checkbox-unique {
margin-right: 10px;
transform: scale(1.5);
}

.feedback-submit-button-unique {
padding: 10px 15px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
font-size: 1rem;
cursor: pointer;
transition: background-color 0.3s ease;
}

.feedback-submit-button-unique:disabled {
background-color: #6c757d;
cursor: not-allowed;
}

.feedback-status-message-unique {
margin-top: 20px;
text-align: center;
color: #007bff;
font-weight: bold;
}


/* 

Type of services

*/

.type-services-container {
max-width: 1200px;
margin: 0 auto;
padding: 2rem;
}

.type-services-header {
text-align: center;
margin-bottom: 30px;
}

.type-services-header h1 {
margin-top: 3rem;
color: #333;
}

.type-services-header p {
max-width: 800px;
margin: 0 auto;
line-height: 1.6;
font-size: 1.1rem;
color: #666;
}

.type-services-grid {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
gap: 1rem;
margin-bottom: 2rem;
min-height:400px;
}

.service-section {
background: white;
border-radius: 8px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
overflow: hidden;
display: flex;
flex-direction: column;
height: 100%;
min-height: 400px;
position: relative;
}

.type-image-container {
position: relative;
width: 100%;
padding-top: 56.25%; 
overflow: hidden;
background-color: #f5f5f5; 
}

.type-service-image {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
transform: translateZ(0);
backface-visibility: hidden;
}

.content-container {
display: flex;
flex-direction: column;
flex-grow: 1;
padding: 0.5rem;
}

.service-section h2 {
font-size: 1.5rem;
font-weight: bold;
color: #333;
margin: 0.5rem 1rem 0 1rem;
}

.service-section p {
padding: 0 1rem;
margin-bottom: 1rem;
color: #666;
line-height: 1.5;
font-size: 1.1rem;
}

.suggested-rate {
margin-top: auto;  
padding-top: 1rem;
color: royalblue !important;
font-weight: 600;
}

.type-services-footer {
text-align: center;
margin: 0 auto;
padding: 0;
color: #666;
line-height: 1.6;
font-size: 1.1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
.type-services-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.services-header h1 {
    font-size: 2rem;
}

.service-section h2 {
    font-size: 1.25rem;
}
}

@media (max-width: 480px) {
.type-services-container {
    padding: 1rem;
}

.service-section h2 {
    font-size: 1.25rem;
    margin: 10px 0 10px 15px;
}

.service-section p {
    margin:0;
}

.suggested-rate {
margin-top: 10px !important;  
}

.type-services-grid {
    grid-template-columns: 1fr;
}
}

/* 

Terms of service
Privacy policy

*/
.terms-privacy-page {
max-width: 900px;
margin: 0 auto;
padding: 20px;
background-color: #ffffff;
border-radius: 8px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
font-family: Arial, sans-serif;
line-height: 1.6;
}

.terms-privacy-page h1 {
font-size: 2.5rem;
color: #333333;
text-align: center;
margin-bottom: 20px;
}

.terms-privacy-page h2 {
font-size: 1.75rem;
color: #0056b3;
margin-top: 30px;
margin-bottom: 15px;
}

.terms-privacy-page h3 {
font-size: 1.5rem;
color: #333333;
margin-top: 20px;
margin-bottom: 10px;
}

.terms-privacy-page p {
font-size: 1rem;
color: #555555;
margin-bottom: 15px;
}

.terms-privacy-page a {
color: #007bff;
text-decoration: none;
}

.terms-privacy-page a:hover {
text-decoration: underline;
}

.terms-privacy-page ul {
margin-left: 20px;
list-style-type: disc;
}

.terms-privacy-page ul li {
margin-bottom: 10px;
}


/*

Email verification

*/
.container-verify {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 70vh;
background-color: #f4f4f4;
font-family: Arial, sans-serif;
text-align: center;
padding: 20px;
}

.heading-verify {
font-size: 2rem;
margin-bottom: 20px;
color: #333;
}

.paragraph-verify {
margin-bottom: 20px;
}

.email-links a {
display: row;
margin: 0.5rem 1rem;
color: #007bff;
text-decoration: none;
}

.resend-button {
background-color: #ff0000;
color: white;
border: none;
border-radius: 10px;
padding: 10px 20px;
margin-top: 20px;
}

.resend-button:disabled {
background-color: gray;
cursor: not-allowed;
}

.resend-status {
color: green;
margin-top: 10px;
}

/* 

FAQ

*/

.faq-container {
margin: 0 auto;
width: 900px;
padding: 20px;
background-color: #f9f9f9;
}


.faq-container h2 {
font-weight: 600;
}

.faq-tabs {
display: flex;
justify-content: center;
margin-bottom: 20px;
}

.faq-tab {
padding: 10px 20px;
margin: 0 0.1rem;
border: 1px solid #000000;
border-radius: 4px;
background-color: #f8f9fa;
color: #000000;
cursor: pointer;
transition: background-color 0.2s, color 0.2s;
width: 100%;
}

.faq-tab.active {
background-color: #ff0000;
border: 1px solid #ff0000;
color: white;
font-weight: 700;
}

.faq-item {
margin-bottom: 15px;
border-bottom: 1px solid #ddd;
padding-bottom: 10px;
}

.faq-question {
cursor: pointer;
margin: 0;
transition: color 0.2s;
font-weight: 600;
font-size: 1.1rem;
display: flex;
justify-content: space-between;
}

.faq-answer {
margin: 1.5rem 1.1rem;
font-size: 1.05rem;
color: #555;
}

.faq-others {
    margin: 1rem auto;
    color: #646464;
    }

@media (max-width: 768px) {
.faq-container {
width: auto;
}

.faq-answer {
margin: 1.1rem 0.5rem;
}
}
    

/* 

Updates

*/
.updates-container {
color: #333;
width: auto;
line-height: 1.3;

}

.updates-top {
background-color: papayawhip;
margin: 0;
padding: 1rem;
}

.updates-header {
text-align: center;
margin-bottom: 30px;
}

.updates-header h2 {
font-size: 2.5em;
font-weight: 600;
margin: 3rem 0 10px 0;
}

.updates-header p {
font-size: 1.2em;
color: #555;
}

.updates-section {
max-width: 1100px;
margin: 0 auto;
padding: 0 1rem;
}

.updates-row {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 15px;
}

.updates-section h2 {
font-size: 1.5rem;
font-weight: 600;
color: #ff0000;
margin: 15px 0;
}


.updates-section h3 {
font-size: 1.2rem;
font-weight: 600;
margin: 1.2rem 0 0.2rem 0;
}

.updates-title {
font-size: 1.8em;
color: #005fa3;
margin-bottom: 10px;
flex-shrink: 0;
align-items: flex-start;
margin-top: 0;
}

.updates-sum {
flex-basis: 70%; 
}

.updates-sum p{
margin: 0 0 1.8rem 0;
font-size: 1.1rem;
}

.updates-link {
font-size: 1em;
color: #e50000;
padding: 10px 15px;
text-decoration: underline;
}

.whats-next {
font-size: 1.1rem;
padding: 1rem 2rem;
}

@media (min-width: 768px) {
.updates-row {
flex-direction: row; 
align-items: flex-start; 
justify-content: flex-start; 
gap: 20px;
margin: 1rem auto 3rem auto;
}

.updates-top {
padding: 1rem 20rem;
}

.updates-title {
flex-basis: 25%; 
align-self: flex-start;
margin: 0; 
}

.updates-sum {
flex-basis: 70%; 
}

.whats-next {
padding: 1rem 20rem;
}
}