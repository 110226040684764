/* 
Profile Summary 
*/

.profile-summary {
max-width: 1000px;
margin: 10px auto;
}

.profile-summary h1 {
color: #333;
text-align: center;
margin-bottom: 20px;
font-weight: 600;
}

.profile-content {
background-color: white;
padding: 20px;
border-radius: 8px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.profile-header {
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 20px;
}

.profile-picture-container {
width: 150px;
height: 150px;
margin-right: 20px;
position: relative;
flex-shrink: 0;
}

.profile-initials-profile {
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: whitesmoke;
color: white;
font-size: 50px;
border-radius: 50%;
}  

.profile-picture {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 50%;
border: 3px solid #fff;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-info {
display: flex;
flex-direction: column;
margin-bottom: 10px;
margin-left: 20px;
}

.profile-name {
font-size: 1.5em;
font-weight: 600;
margin-bottom:5px;
}

.user-name-input {
width: 160px;
padding: 12px;
margin-top: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
max-height: 10px;
}

.username-error-message {
color: #ff0000;
font-size: 1rem;
font-weight: 600;
padding: 0.5rem 0;
}

.profile-field {
width: 100%;
box-sizing: border-box;
margin: 10px;  
padding-right: 20px;
font-size: 1.1rem;
}

.profile-field strong{
font-weight: 600;
font-size: 1.2rem;
margin-bottom: 10px;
padding-bottom: 10px;
}

.profile-rating {
display: flex;
align-items: center;
font-size: 1.2em;
}

.profile-rating svg {
color: #808080;
width: 16px;
height: 16px;
margin-top: 4px;
}

.profile-rating span {
margin-left: 5px;
font-weight: 600;
color: #333;
}

.profile-rating-label {
margin-right: 10px;
font-weight: bold;
}

.edit-mode {
width: 100%;
box-sizing: border-box;
}

.edit-mode input[type="text"],
.edit-mode textarea {
width: 100%;
padding: 8px;
margin-top: 5px;
border: 1px solid #ddd;
border-radius: 4px;
}

.edit-mode input[type="text"] {
height: 40px;
width: 90%;
}

.edit-mode textarea {
width: 100%;
padding: 8px;
margin-top: 5px;
border: 1px solid #ddd;
border-radius: 4px;
height: 96px;
resize: none;
}

.char-count {
font-size: 0.8em;
color: #777;
text-align: right;
}

.button-group button {
min-height: 40px;
font-size: 15px;
min-width: 33%;
border-radius: 10px;
border: 1;
}

.next-button{
margin-left: 5px;
min-height: 40px;
font-size: 1rem;
min-width: 200px;
color: white;
border-radius: 10px;
background-color: royalblue;
border: none;
box-shadow: none;
}

.cancel-button-one {
margin-left: 5px;
min-height: 40px;
min-width: 200px;
margin-right: 20px;
color: white;
border-radius: 10px;
font-size: 1rem;
background-color: rgb(255, 0, 0);
border: none;
box-shadow: none; 
}

.file-input {
height: 40px;
padding: 8px;
margin-top: 10px;
font-size: 1rem;
}

.city-edit{
width: auto;
padding: 12px;
margin-top: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
max-height: 10px;
}


.about-edit {
width: 100%;
padding: 12px;
margin-top: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
min-height: 100px;
box-sizing: border-box;
resize: vertical;
}
.upload-photo-container {
margin-top: 10px;
display: flex;
justify-content: center;
}

.upload-photo-button {
display: inline-block;
padding: 5px 5px;
font-size: 13px;
background-color: #f0f0f0;
border: 1px solid #ddd;
border-radius: 4px;
cursor: pointer;
}



.upload-photo-button:hover {
background-color: #e0e0e0;
}

.selected-file {
margin-left: 10px;
font-size: 0.9em;
color: #666;
}

.profile-field-value {
width: 100%; 
box-sizing: border-box;
overflow: hidden;
margin-top: 5px;
font-size: 1.1rem;
}

.camera-icon-profile {
position: absolute;
bottom: 55px;
right: 55px;
cursor: pointer;
z-index: 10;
}

.save-profile {
background-color: royalblue;
color: white;
min-height: 30px;
min-width: 190px;
margin-right: 20px;
color: white;
border-radius: 10px;
border: none;
box-shadow: none; 
}

.remove-photo-button {
background-color: #dc3545;
color: white;
min-height: 30px;
min-width: 190px;
margin-right: 20px;
color: white;
border-radius: 10px;
border: none;
box-shadow: none;
font-size: 1rem;
}

.remove-photo-button:hover {
background-color: #c82333;
}

.link-to-dashboard {
padding: 6px 10px; 
font-size: 1rem;
background-color: royalblue;
color: #ffffff;
border-radius: 10px;
border: none;
cursor: pointer;
min-height: 35px;
width: auto;
}

.back-to-dash {
display: block;
width: 100%;
padding: 15px;
text-align: center;
background-color: rgb(255, 0, 0);
color: rgb(255, 255, 255);
font-weight: bold;
border: none;
cursor: pointer;
position: sticky;
top: 0; 
font-size: 1rem;
z-index: 10; 
}

.service-profile {
max-width: 1000px;
margin: 10px auto;
}

@media (max-width: 768px) {
.profile-summary {
max-width: 900px;
padding: 5px 10px;
}

.profile-header {
flex-direction: row;
justify-content: center;
}

.profile-picture-container {
margin-right: 10px;
margin-bottom: 0;
}

.link-to-dashboard {
margin: 10px 10%;
width: 80%;
height: 40px;
}

.profile-picture {
width: 70%;
height: 70%;
margin-left: 25px;
margin-top: 25px;
object-fit: cover;
border-radius: 50%;
}

.profile-initials-profile {
width: 70%;
height: 70%;
margin-left: 25px;
margin-top: 25px;
} 

.profile-rating span {
width: 70px;
}

.profile-info {
align-items: flex-start;
text-align: left;
margin: 25px 10px 10px -5px;
}

.profile-field {
flex-direction: row;
align-items: center;
}

.profile-field strong {
width: 80px;
margin-right: 10px;
text-align: right;
margin-bottom: 0;
}

.upload-photo-container {
flex-direction: row;
}

.upload-photo-button,
.remove-photo-button {
margin-bottom: 0;
min-width: 150px;
margin-right: 10px;
}

.file-input {
font-size: 1rem;
}
}


/* 

Service Summary 

*/

.camera-icon {
position: absolute;
top: 18px;  
right: 18px; 
cursor: pointer;
background-color: rgba(255, 205, 205, 0.7); 
border-radius: 50%; 
padding: 4px; 
z-index: 100; 
height: 40px;
width: 40px;
pointer-events: auto; 
}

.service-summary {
width: 100%; 
margin: 0;
padding: 1px;
box-sizing: border-box;
}

.service-summary-container {
background-color: white; 
border-radius: 5px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
padding: 20px;
width: 100%; 
box-sizing: border-box;
}

.service-summary-top h1 {
color: #333;
text-align: center;
margin-bottom: 20px;
font-size: 1.5rem;
}

.service-cards {
display: flex;
flex-wrap: wrap;
gap: 20px;
justify-content: flex-start;
font-size: 1.1rem;
}

.service-rating {
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 20px;
font-size: 1.2em;
width: 100%;
}

.service-rating span {
margin-left: 5px;
}

.service-rating svg {
width: 20px; 
height: 20px;
}

.service-card, .add-service-card {
width: calc(33.333% - 20px);
background-color: papayawhip;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
display: flex;
flex-direction: column;
cursor: pointer;
transition: transform 0.3s ease;
overflow: hidden;
}

.add-service-card {
justify-content: center;
align-items: center;
background-color: #eaeaea;
}

.add-service-card h2 {
text-align: center;
padding: 20px;
font-weight: 600;
font-size: 1.3rem;
}

.add-service-card:hover {
background-color: #e0e0e0;
}

.service-category-tag {
position: absolute;
top: 8px;
right: 8px;
background-color: rgba(239, 239, 239, 0.9);
color: #000000;
padding: 4px 8px;
border-radius: 4px;
font-size: 1rem;
z-index: 10;
}

.service-image {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
background-color: white;
}

.service-info {
padding: 0 0 0 1rem;
margin: 0;
flex-grow: 1; 
display: flex;
flex-direction: column;
}

.service-info-row {
display: flex;
justify-content: space-between; 
align-items: center; 
gap: 10px; 
margin-bottom: 1rem;
}

.service-name {
font-size: 1.2rem;
margin: 1rem 0;
font-weight: 600;
}

.service-price {
margin: 0;
}

.service-details {
padding: 1rem;
margin-top: auto; 
}

.service-actions {
display: flex;
justify-content: space-between;
}

.edit-button, .visibility-button {
padding: 8px 16px;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.3s;
}

.edit-button {
color: #5a5a5a;
background-color: whitesmoke;
border: 1px solid #5a5a5a;
font-size: 1rem;
} 

.visibility-button {
background-color: #717171;
color: white;
font-size: 1rem;
}

.visibility-button:hover {
background-color: #5a0000;
}

.visibility-button.private {
background-color: #00c932;
color: white;
}

.visibility-button.private:hover {
background-color: #ffffff;
border: 1px solid green;
color: green;
}

@media (max-width: 768px) {
.edit-button, .visibility-button {
width: 49%;
}
}

/* 

Adding Service Details 

*/

.service-adding-container {
min-width: 900px; 
max-width: 1100px;
margin: 0 auto;
padding: 20px;
background-color: #f9f9f9;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
display: flex;
flex-direction: column;
}

.service-adding-container  h2 {
text-align: center;
margin-bottom: 20px;
color: #333;
}

.form-group {
display: flex;
position: relative;
flex-direction: column;
margin-bottom: 20px;
}

.form-group-time {
position: relative;
}

.form-group-time h4 {
margin: 10px;
}

.form-group-time label {
margin: 5px;
}

.category-dropdown {
font-size: 1.1rem;
height: 3rem;
border-radius: 4px;
}

.service-title-input::placeholder,
.service-textarea::placeholder {
color: rgb(190, 190, 190); 
opacity: 1; 
}

.form-group label {
margin-bottom: 5px;
font-weight: 600;
color: #555;
margin: 0 5px;
}

.time-option-label {
font-weight: 600 !important;
}

.day-dropdown {
height: 40px; 
font-size: 1rem;
}

.input, .textarea {
width: 100%;
padding: 12px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
}

.service-title-input {
padding: 12px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 18px;
}

.pricing-input-with-unit {
display: flex;
align-items: center; 
}

.price-input {
padding: 12px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
margin-right: 10px;
}

.address-input {
padding: 12px;
font-size: 16px;
border: 1px solid #ccc;
border-radius: 4px;
width: 90%;
}

.suggestions-list {
list-style-type: none;
padding: 0;
margin: 0;
border: 1px solid #ccc;
max-height: 120px;
overflow-y: auto;
top:55%;
position: absolute;
width: 100%;
background-color: white;
z-index: 1000;
}

.suggestions-list li {
padding: 10px;
cursor: pointer;
}

.suggestions-list li:hover {
background-color: #f0f0f0;
}

.service-textarea {
padding: 12px;
resize: none;
height: 400px;
font-size: 18px;
border: 1px solid #ccc;
border-radius: 4px;
}

.textarea {
resize: none;
height: 200px;
}

.warning {
color: red;
font-size: 1.1rem;
margin-top: 5px;
}

.service-button-group {
display: flex;
justify-content: space-between;
margin-top: 10px;
min-height: 40px;
border-radius: 10px;
}

.service-button-group button{
border-radius: 10px;
font-size: 15px;
min-height: 40px;
min-width: 33%;
}

.button:hover {
background-color: #0056b3;
}

.cancel-button {
background-color: white;
min-height: 40px;
border-radius: 10px;
color: red;
border: 1px solid red;
min-width: 100px;
}

.cancel-button:hover {
background-color: #ffe6e6;
}

.previous-button {
background-color: white;
color: grey;
border: 1px solid grey;
}

.previous-button:hover {
background-color: #f0f0f0;
}

.photo-gallery {
display: flex;
flex-wrap: wrap;
justify-content: flex-start; 
align-items: flex-start;
gap: 10px; 
}

.main-photo-frame, .sub-photo-frame {
width: 19%; 
height: 110px;
border: 1px solid #ccc;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
background-color: #f0f0f0;
position: relative;
overflow: hidden;
}

.main-photo-frame img,
.sub-photo-frame img {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 5px;
}

.empty-photo-frame {
display: flex;
align-items: center;
justify-content: center;
color: #aaa;
font-size: 1.1rem;
width: 100%;
height: 100%;
text-align: center;
}

.photo-actions {
display: flex;
justify-content: space-between;
margin-top: 5px;
}

.crop-button {
background-color: #000000b3;
color: white;
}

.photo-delete-button { 
background-color: red;
color: white;
}

.crop-button, 
.photo-delete-button {
border: none;
color: white;
padding: 0.5rem;
margin: 1rem;
font-size: 1.1rem;
font-weight: 600;
border-radius: 10px;
z-index: 10;
}
  
.cropper-modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

.cropper-container {
position: relative;
width: 80%;
height: 80%;
background: white;
background-color: #444444;
border-radius: 8px;
overflow: hidden;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.cropper-buttons {
margin-top: 30%;
display: flex;
justify-content: space-between;
padding: 10px 20px;
}

.save-crop-button,
.cancel-crop-button {
background-color: #4caf50;
border: none;
color: white;
padding: 10px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin: 1rem;
cursor: pointer;
border-radius: 4px;
z-index: 1000;
}

.cancel-crop-button {
background-color: #dc3545;
}

.save-crop-button:hover,
.cancel-crop-button:hover {
opacity: 0.9;
}

.update-photos-button{
background-color: royalblue;
color:white;
min-height: 40px;
font-size: 1rem;
border: none;
margin: 5px;
width: 100%;
border-radius: 10px;
} 

.cancel-update-button{
color: red;
margin: 5px;
width: 100%;
min-height: 40px;
font-size: 1rem;
border: none;
border-radius: 10px;
} 

.overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
color: white;
display: flex;
align-items: center;
justify-content: center;
font-size: 1.2em;
border-radius: 5px;
}

.delete-button {
position: absolute;
top: 10px;
right: 10px;
background: none;
color: white;
font-size: 2em;
border: none;
text-shadow: 0 0 3px black;
cursor: pointer;
}

.service-delete-button {
background-color: rgb(255, 0, 0);
color: rgb(0, 0, 0);
border: 2px solid red;
font-size: 1rem;
border-radius: 10px;
min-height: 40px;
cursor: pointer;
width: auto;
height: 30px;
}

.preserve-line-breaks {
white-space: pre-wrap;
}

.desktop-only {
display: inline-block;
}

.listing-progress-indicator {
margin: 0;
text-align: center;
}

.listing-progress-bar {
width: 100%;
height: 8px;
background-color: #f0f0f0;
overflow: hidden;
}

.listing-progress-bar-filled {
height: 100%;
background-color: royalblue;
transition: width 0.3s ease-in-out;
}

@media (max-width: 768px) {
.service-adding-container {
min-width: auto; 
padding: 1rem;
width: 100%;
box-sizing: border-box;
}

.update-photos-button, .cancel-update-button {
margin: 5px;
width: 100%;
height: 35px;
font-size: 1rem;
border: solid 0.5px;
}    

.form-group {
margin-bottom: 15px;
}

.form-group-time {
margin-bottom: 15px;
display: flex;
flex-direction: column;
}

.form-group-time h4{
margin: 5px;
  }

.service-adding-container h2 {
font-size: 1.5rem; 
margin-bottom: 15px; 
}

.input, .textarea, .service-title-input, .price-input, .address-input {
width: 100%;
box-sizing: border-box;
}

.service-button-group {
flex-direction: column;
align-items: stretch;
justify-content: flex-end;
}

.service-button-group button {
margin-bottom: 10px;
width: 100%;
min-width: 33%;
margin-left: 0;
}

.service-textarea {
height: 200px; 
}

.pricing-input-with-unit {
flex-direction: row;
align-items: flex-start;
}

.pricing-input-with-unit p{
margin-left: 10px;
}

.price-input {
width: 60%;
margin-right: 0;
margin-bottom: 5px;
}

.cropper-buttons {
margin-top: 100%;
}

.photo-gallery {
gap: 10px;
justify-content: center;
}

.main-photo-frame, .sub-photo-frame {
width: 100%;
height: 210px;
position: relative;
overflow: hidden;
}

.main-photo-frame img, .sub-photo-frame img {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
}

.empty-photo-frame {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
font-size: 0.9em;
}

.crop-button, 
.photo-delete-button {
padding: 1rem 2rem;
margin: 1rem 2rem;
}

.delete-button {
position: absolute;
top: 4px; 
right: 5px;
background: rgba(55, 55, 55, 0.5);
color: white;
border: none;
border-radius: 50%;
width: 20px;
height: 20px;
font-size: 16px;
line-height: 1;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
z-index: 10; 
}

.desktop-only {
display: none;
}
}

/*

Service Dash

*/

.service-summary-top {
max-width: 900px;
margin: 1rem auto;
padding: 20px;
background-color: #f5f5f5;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
width: 100%;
}

.service-summary-top p{
font-size: 1.2rem;
}

.stripe-connect-button {
padding: 6px 10px;
background-color: #6B71E3;
color: #ffffff;
border-radius: 5px;
border: none;
cursor: pointer;
min-height: 35px;
width: auto;
margin-top: 0;
white-space: nowrap;
font-size: 1rem;
}

.service-field-phone {
display: flex;
align-items: center;
margin-bottom: 10px;
font-size: 1.1rem;
}

.service-field-phone span{
margin: 0 10px;
}

.service-field-phone strong {
font-weight: 600;
}

.service-field-value {
flex-grow: 1;
text-align: left;
}

.dashboard-box-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 20px;
}

.dashboard-box {
width: 48%;
height: 180px;
padding: 20px 0;
background-color: white;
text-align: center;
cursor: pointer;
border: solid 1px gray;
border-radius: 10px;
align-content: center;
margin: 10px 0;
}

.dashboard-box-stripe {
width: 48%;
height: 180px;
padding: 20px 0;
text-align: center;
cursor: pointer;
border: solid 4px #5433FF;
color: #5433FF;
border-radius: 10px;
align-content: center;
margin: 10px 0;
}

.dashboard-box h2,
.dashboard-box-stripe h2 {
font-weight: 600;
margin: 0px 0px 10px 0px;
}

.dashboard-box p,
.dashboard-box-stripe p {
font-size: 14px;
color: #555;
padding: 0 5px;
margin: 0 auto;
}

.tabs-container {
display: flex;
/* border-bottom: 1px solid whitesmoke; */
margin-bottom: 8px; 
margin-top: 16px; 
width: 100%;
box-sizing: border-box;
height: 40px;
font-size: 1.1rem;
align-items: center;
justify-content: center;
border: 1px;
}

.tabs {
border: whitesmoke 1px solid;
width: 100%;
height: 40px;
font-size: 1.1rem;
background-color: lightgrey;
}

.tabs.active{
background-color: red;
border: whitesmoke 1px solid;
color: white;
}

.private-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(102, 99, 99, 0.6);
color: white;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
z-index: 10;
}

.payout-section-dash {
display: flex;
flex-direction: column;
align-items: flex-start; 
width: 100%;
margin: 0; 
padding: 16px; 
}

.payout-section-dash h2, 
.payout-section-dash p {
margin-left: 0; 
margin-right: 0; 
padding-left: 0; 
}

.payout-section-dash a {
margin-left: 0; 
}

.service-rating-container {
display: flex;
flex-direction: column; 
align-items: center; 
max-width: 100%;
margin: 1rem 0.5rem; 
padding-top: 0.5rem;
background-color: #ffffff;
border-radius: 8px;
box-sizing: border-box;
width: 100%; 
}

.service-rating-value-label {
margin-right: 10px; 
font-weight: 600; 
font-size: 1.2rem; 
line-height: 1.15; 
}

.review-stars-container {
display: flex;
gap: 1px;
}
.star-filled {
color: gold;
fill: gold;
}

.star-empty {
color: lightgray;
fill: lightgrey;
}

.service-reviews-sum {
width: 100%;
margin-top: 15px; 
background-color: #f5f5f5;
padding: 20px;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
overflow-y: auto;
}

.service-reviews-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
}

.report-user-button {
color: #ff0000;
text-decoration: none;
font-weight: 600;
font-size: 0.9rem;
margin: 0 5px;
}

.write-review-button {
padding: 6px 0.6rem; 
font-size: 1rem;
font-weight: 600;
color: cornflowerblue;
background-color: rgb(255, 255, 255);
border-radius: 25px;
border: none;
cursor: pointer;
min-height: 35px;
width: auto;
margin-left: auto;
}

.load-more-reviews {
padding: 6px 10px; 
background-color: royalblue;
color: #ffffff;
border-radius: 5px;
border: none;
cursor: pointer;
min-height: 35px;
width: auto;
}

.popup {
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: rgb(255, 0, 0);
padding: 4px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
z-index: 1000; 
}

.popup-content {
background: rgb(255, 255, 255);
padding: 20px;
text-align: center;
}

@media (max-width: 768px) {
.dashboard-box,
.dashboard-box-stripe {
margin-bottom: 12px;
width: 49%;
height: 140px;
}

.service-reviews-sum {
padding: 20px 5px 10px 5px;
}

.service-reviews-header {
margin-bottom: 10px;
padding-left: 5px;
}

.dashboard-box-container {
margin-top: 10px;
}

.service-field-phone {
display: flex;
align-items: center;
margin-bottom: 10px;
}

.service-field-phone strong {
margin-bottom: 5px;
}

.service-field-phone span {
display: inline-block;
margin-top: 5px;
}

.service-field-phone span {
margin: 0 5px;
}
}

/* 

Verify phone

*/

.phone-input {
max-width: 1200px;
min-width: 800px;
margin: 0 auto;
padding: 20px;
background-color: #f8f9fa;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
height: auto;
}

.phone-input .button-group {
display: flex;
justify-content: space-between;
gap: 10px;
}

.phone-number-row {
margin-top: 50px;
}

.phone-input h2 {
font-size: 1.5em;
color: #333;
margin-bottom: 1em;
text-align: center;
}

.phone-input label {
display: block;
font-weight: 600;
color: #555;
margin-bottom: 8px;
}

.phone-input input[type="text"] {
width: 100%;
padding: 10px;
margin-bottom: 16px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 1em;
}

.phone-input button {
padding: 10px 20px;
font-size: 1.1rem;
border-radius: 4px;
cursor: pointer;
width: 48%;
margin: 5px;
}

.checkbox-phone {
margin-bottom: 20px;
}

.checkbox-warning p {
margin-bottom: 20px;
color:#5a0000;
text-decoration: underline;
}

.save-phone {
background-color: royalblue;
color: #fff;
border: none;
margin-right: 8px;
}

.save-phone:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.cancel-phone-button {
background-color: #dc3545;
color: #fff;
border: none;
}

.button-group-phone {
margin-top: 20px;
}


@media (max-width: 767px) {
.phone-input {
width:auto;
min-width: 95%;
padding: 10px;
}

.phone-input h2 {
font-size: 1.3em;
color: #333;
margin: 30px auto;
text-align: center;
}

.phone-input button {
width: 100%;
}
}

/* 

My jobs, My orders

*/
.seller-orders-page, .my-orders-page {
width: 100%; 
margin: 0 auto; 
padding: 20px; 
box-sizing: border-box;
}

.order-status-section {
width: 100%;
display: flex;
flex-wrap: wrap;
margin: 0;
}

.order-status-section h2{
font-size: 1.4rem;
width: 100%;     
font-weight: 600;
margin: 20px 0 7px 0;
margin-top: 0;
}

.order-card {
background-color: #f9f9f9;
border: 1px solid #ddd;
border-radius: 8px;
box-sizing: border-box;
display: flex;
flex-direction: row;
margin: 0 10px 15px 10px;
padding: 15px;
width: 23%;
max-width: 32%;
flex-grow: 1;
}

.order-card strong {
font-size: 1.1rem;
font-weight: 600;
}

.order-info {
display: flex;
flex-direction: column;
width: 100%;
position: relative;
}

.order-info span {
flex: 1;
margin-bottom: 2px;
}

.order-actions {
display: flex;
justify-content: flex-end;
gap: 10px;
margin-top: 10px;
width: 100%;
}

.button-container {
display: flex;
justify-content: flex-end;
margin-top: 10px;
}

.myjobs-button-accept {
padding: 6px 10px; 
background-color: royalblue;
color: rgb(255, 255, 255);
border: 1px solid royalblue; 
border-radius: 5px;
cursor: pointer;
min-height: 35px;
transition: background-color 0.3s ease, color 0.3s ease; 
width: 100%;
font-size: 1rem;
}

.myjobs-buttons, 
.myorders-buttons {
padding: 6px 10px; 
background-color: #ffffff !important;
color: #ff0000;
border: 1px solid red; 
border-radius: 5px;
cursor: pointer;
min-height: 35px;
transition: background-color 0.3s ease, color 0.3s ease; 
width: 100%;
font-size: 1rem;
}

.myjobs-buttons:hover,
.myorders-buttons {
background-color: #ff0000 !important; 
color: #ffffff; 
}

.myjobs-reviews,
.myorders-reviews {
padding: 6px 10px; 
background-color: royalblue !important;
color: rgb(255, 255, 255);
border: 1px solid royalblue; 
border-radius: 5px;
cursor: pointer;
min-height: 35px;
transition: background-color 0.3s ease, color 0.3s ease; 
width: 100%;
font-size: 1rem;
}

.myjobs-reviews:hover,
.myorders-reviews:hover {
background-color: rgb(255, 255, 255) !important; 
color: royalblue; 
}

@media (max-width: 768px) {
.service-summary-top {
margin: 0 auto;
padding: 20px; 
height: 100%;
}

.service-field-stripe {
display: flex;
flex-direction: row; 
align-items: center;
justify-content: space-between;
margin-bottom: 10px;
font-size: 16px;
width: 100%;
box-sizing: border-box;
flex-wrap: nowrap; 
}

.service-field-stripe strong {
margin-right: 10px;
white-space: nowrap;
font-weight: 600;
}

.service-field-value {
flex-grow: 1;
text-align: left;
margin-right: 20px; 
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.stripe-connect-button {
margin: 10px auto;
width: 100%; 
height: 40px;
}

.tabs {
width: 100%;
font-size: 1rem;
}

.payout-section-dash, .service-rating-container {
width: 100%;
max-width: none;  
box-sizing: border-box;
margin: 0;
}

.service-card, .add-service-card {
width: 100%; 
margin-bottom: 10px; 
}

.order-card {
width: 100%;
margin: 0 0 15px 0;
max-width: none;
}
}

/*

My review & Service review

*/

.my-reviews-top {
padding: 20px;
}

.my-reviews-sum {
max-width: 1000px;
margin: 10px auto;
padding: 20px;
background-color: #f5f5f5;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
min-height: 150px;
max-height: 350px;
overflow-y: auto;
}

.my-reviews-sum h2 {
font-size: 1.3rem;
font-weight: 600;
}

.my-reviews-sum p,
.service-reviews-sum p {
font-size: 1.1rem;
}

.review {
background-color: #ffffff;
border-radius: 12px;
padding: 15px;
margin: 15px auto;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
border: 1px solid #e0e0e0;
overflow-wrap: break-word; 
}

.review-header {
display: flex;
align-items: center;
margin-bottom: 10px;
font-weight: 600;
color: #333;
justify-content: space-between;
gap: 8px;
}

.reviews-header-row {
display: flex;
align-items: center;
gap: 30px;
}

.review-header span {
font-size: 1rem;
}

.review-date {
margin-left: auto;
color: #666;
font-size: 0.9em !important;
font-weight: 500;
}

.review-comment {
font-size: 1rem;
color: #666;
line-height: 1.5;
margin-top: 5px;
white-space: pre-wrap; 
transition: max-height 0.2s ease;
overflow: hidden;
}

.review-comment.collapsed {
display: -webkit-box;
-webkit-line-clamp: 4; 
line-clamp: 4; 
-webkit-box-orient: vertical;
box-orient: vertical; 
overflow: hidden;
}

.review-comment.expanded {
-webkit-line-clamp: unset; 
line-clamp: unset;
max-height: none;
}

.create-profile-container {
min-width: 400px;
margin: 0 auto;
padding: 20px;
background-color: #f9f9f9;
border-radius: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.create-profile-container h1 {
text-align: center;
font-size: 2rem;
margin-bottom: 20px;
color: #333;
}

.create-profile-form {
display: flex;
flex-direction: column;
}

.create-profile-field {
margin-bottom: 15px;
}

.create-profile-field label {
font-size: 1rem;
color: #555;
margin-bottom: 5px;
display: block;
}

.create-profile-field input {
width: 100%;
padding: 10px;
border-radius: 5px;
border: 1px solid #ddd;
font-size: 1rem;
box-sizing: border-box;
}

.create-profile-field input:focus {
border-color: #007bff;
outline: none;
box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.create-profile-submit {
padding: 10px 20px;
font-size: 1.1rem;
color: #fff;
background-color: #007bff;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s;
}

.create-profile-submit:hover {
background-color: #0056b3;
}

.create-profile-submit:active {
background-color: #004085;
}

@media (max-width: 600px) {

.reviews-header-row {
  display: flex;
  align-items: center;
  gap: 0;
  }

.create-profile-container {
padding: 15px;
}

.create-profile-container h1 {
font-size: 1.8rem;
}

.create-profile-submit {
font-size: 1rem;
}

.review-date {
font-size: 0.8em !important;
}
}

/*

Save Name
Seller Registration

*/
.store-name-container,
.seller-registration-container {
background-color: #fff;
border: 1px solid #ddd;
border-radius: 8px;
box-shadow: 0 4px 8px #0000001a;
margin: 20px auto;
min-width: 1000px;
max-width: 1100px;
padding: 30px;
margin-top: 20px !important;
}

.store-name-container h2,
.seller-registration-container h2 {
text-align: center;
font-size: 24px;
color: #333;
margin-bottom: 20px;
}

.store-name-form label,
.seller-registration-form label {
display: block;
font-weight: bold;
margin-bottom: 5px;
color: #555;
}

.store-name-form input[type="text"],
.seller-registration-form input[type="text"],
.seller-registration-form input[type="date"] {
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
margin-bottom: 15px;
font-size: 16px;
color: #333;
}

.store-name-form input[type="text"]
.seller-registration-form input[type="text"]:focus,
.seller-registration-form input[type="date"]:focus {
border-color: #007bff;
outline: none;
box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}

.store-name-container button,
.seller-registration-form button {
padding: 10px 15px;
border: none;
border-radius: 4px;
font-size: 16px;
cursor: pointer;
margin-right: 10px;
}

.seller-registration-form button:first-of-type {
background-color: #007bff;
color: #fff;
}

.seller-registration-form button:first-of-type:hover {
background-color: #0056b3;
}

.seller-registration-form button:last-of-type {
background-color: #ccc;
color: #333;
}

.seller-registration-form button:last-of-type:hover {
background-color: #999;
}

.validation-error {
color: red;
font-size: 14px;
margin-top: -10px;
margin-bottom: 15px;
}

.profile-view p {
font-size: 16px;
margin-bottom: 10px;
color: #606060;
}

.profile-view p strong {
color: #251e7a;
}

.profile-view button {
display: block;
margin: 20px auto 0;
padding: 10px 15px;
background-color: #007bff;
color: #fff;
border: none;
border-radius: 4px;
font-size: 16px;
cursor: pointer;
}

.profile-view button:hover {
background-color: #0056b3;
}

@media (max-width: 600px) {
.store-name-container,
.seller-registration-container {
margin: 10px;
padding: 20px;
min-width: 0;
}  
}

/*

Calender Products

*/

.calendar-container {
padding: 1rem;
background-color: white;
border-radius: 0.5rem;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
overflow-x: auto;
max-width: 1020px;
}

.calendar-section {
width: 100%;
max-width: 1020px;
}

.calendar-header {
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 0.5rem;
}

.calendar-title {
font-size: 1.125rem;
font-weight: 600;
}

.calendar-navigation {
display: flex;
gap: 0.5rem;
}

.calendar-nav-button {
display: flex;
align-items: center;
gap: 0.25rem;
padding: 0.25rem 0.75rem;
font-size: 0.875rem;
background-color: #EBF5FF;
color: #2563EB;
border-radius: 0.25rem;
border: none;
cursor: pointer;
transition: background-color 0.2s;
}

.calendar-grid {
display: grid;
grid-template-columns: repeat(8, 1fr);
gap: 0.25rem;
min-width: 800px;
}

.time-header {
height: 3rem;
display: flex;
align-items: flex-end;
justify-content: center;
font-weight: 600;
color: #a8d0ff;
position: sticky;
left: 0;
background-color: white;
min-width: 60px;
}

.day-header {
height: 3rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
padding-bottom: 0.25rem;
}

.day-name {
font-weight: 600;
}

.day-date {
font-size: 0.875rem;
color: #6B7280;
}

.time-label {
position: sticky;
left: 0;
background-color: white;
border-top: 1px solid #E5E7EB;
height: 2rem;
display: flex;
align-items: center;
justify-content: center;
font-size: 0.875rem;
color: #6B7280;
min-width: 60px;
}

.time-slot {
border-top: 1px solid #E5E7EB;
height: 2rem;
}

.time-slot-past {
background-color: #9b9b9b; 
cursor: not-allowed;
}

.time-slot-in-preference {
background-color: #a8d0ff;  
cursor: pointer;
}

.time-slot-out-of-preference {
background-color: #cccccc;  
cursor: pointer;
}

.availability-not-provided {
padding: 1rem;
background-color: white;
border-radius: 0.5rem;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.availability-not-provided-title {
font-size: 1.125rem;
font-weight: 600;
margin-bottom: 0.5rem;
}

.availability-not-provided-text {
color: #6B7280;
}

.time-slot-clickable {
cursor: pointer;
transition: all 0.2s ease;
}

.time-slot-clickable:hover {
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selected-slot-info {
padding: 1rem;
background-color: #f8f9fa;
border-radius: 0.5rem;
margin: 1rem 0;
}

.selected-slot-info p {
margin: 0.5rem 0;
font-size: 1.1rem;
color: #374151;
}

.slot-warning-text {
font-style: italic;
color: #565fff !important;
}

.time-slot:hover:not(.time-slot-past) {
opacity: 0.8;
}

.selected-slot-actions {
display: flex;
flex-direction: column;
gap: 0.75rem;
margin-top: 1rem;
}

.slot-action-button {
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
padding: 0.75rem;
border-radius: 0.375rem;
font-weight: 500;
transition: all 0.2s;
border: none;
cursor: pointer;
width: 100%;
}

.timeslot-message-button, .timeslot-call-button,  
.timeslot-booking-button {
color: white;
font-size: 1.1rem;
}

.timeslot-message-button {
background-color: rgb(0, 66, 37);
}

.timeslot-call-button {
background-color: #1700ae;
}

.timeslot-booking-button {
background-color: #ff0000;
}

@media (max-width: 768px) {
.calendar-container {
  padding: 0.5rem;
  overflow-x: hidden;
  margin-bottom: 1rem;
}

.calendar-grid {
  min-width: unset;
  grid-template-columns: auto repeat(3, 1fr);
}

.nav-button {
  padding: 0.25rem 0.5rem;
}

.nav-button span {
  display: none;
}

.calendar-title {
  font-size: 1rem;
}

.day-header {
  padding: 0.25rem;
}

.day-name {
  font-size: 0.875rem;
}

.day-date {
  font-size: 0.75rem;
}

.time-label {
  font-size: 0.75rem;
  padding: 0 0.25rem;
  min-width: 50px;
}

.time-header {
  min-width: 50px;
}

.time-slot {
  height: 1.75rem;
}
.selected-slot-actions {
  gap: 0.8rem;
}

.slot-action-button {
  padding: 0.625rem;
  font-size: 0.875rem;
}

.selected-slot-info {
  padding: 0.75rem;
}
}

/*

Modal

*/
.modal-overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
-webkit-overflow-scrolling: touch;
}

.modal-content {
background: white;
padding: 1.5rem;
border-radius: 0.5rem;
width: 90%;
max-width: 500px;
position: relative;
animation: modalFadeIn 0.3s ease;
-webkit-overflow-scrolling: touch;
}

@keyframes modalFadeIn {
from {
  opacity: 0;
  transform: translateY(-20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

.modal-header {
margin-bottom: 1.5rem;
}

.modal-title {
font-size: 1.25rem;
font-weight: 600;
color: #374151;
}

.modal-close {
position: absolute;
top: 1rem;
right: 1rem;
background: none;
border: none;
font-size: 1.5rem;
cursor: pointer;
color: #6B7280;
}

.modal-close:hover {
color: #374151;
}