/*

Browse page

*/

.browse-container {
display: flex;
height: 100%;
position: relative;
}

.filter-sidebar {
width: 230px;
padding: 20px;
background-color: #f4f4f4;
border-right: 1px solid #ddd;
overflow-y: auto;
display: flex;
flex-direction: column;
}

.toggle-map-btn {
display: block;
width: auto; 
margin: 10px 0;
color: rgb(0, 0, 0);
border: 2px solid royalblue;
background-color: royalblue;
text-align: center;
height: 50px;
display: flex;
position: relative;
justify-content: center;
align-items: center;
}

.toggle-map-btn img {
width: 100%;  
height: 100%;   
object-fit: cover; 
position: absolute; 
top: 0;
left: 0;
z-index: 1; 
opacity: 0.3;
}

.button-text {
position: relative;  
font-size: 1.1rem;   
font-weight: 600;  
color: whitesmoke;
z-index: 2;          
text-align: center;
}

.toggle-map-btn.map-active {
background-color: rgb(33, 57, 130);
color: rgb(255, 255, 255); 
border: 1px solid royalblue;
}

.filter-sidebar input,
.filter-sidebar .price-filter
.filter-sidebar .category-filter {
width: 100%;
padding: 10px;
margin-bottom: 15px;
border-radius: 15px;
box-sizing: border-box;
border: 1px solid #ddd;
outline: none;
}

.price-filter,
.category-filter,
.availability-filter {
display: flex;
flex-direction: column;
font-weight: 500;
}

.price-filter h3,
.category-filter h3,
.availability-filter h3 {
display: flex;
flex-direction: column;
font-weight: 600;
}

.price-option,
.category-option,
.availability-option {
display: flex;
align-items: center;
margin-bottom: 5px;
margin-left: 2px;
}

.price-option input[type="checkbox"],
.category-option input[type="checkbox"],
.availability-option input[type="checkbox"] {
margin-right: 5px;
margin-left: 5px;
width: auto;
}

.price-option label,
.category-option label,
.availability-option label {
font-size: 1rem;
margin-left: 5px;
margin-bottom: 11px;
}

.browse-service-image-container {
max-height: 580px;
overflow: hidden;
margin-bottom: 20px;
position: relative;
padding-top: 53%; 
margin: 0;
}
  

.browse-main {
flex: 1;
padding: 20px;
overflow: hidden;
display: block;
}

.product-container {
display: grid;
grid-template-columns: repeat(auto-fill, 345px);
gap: 10px;
width: 100%;
}

.map-container {
flex: 1;
min-height: 350px;
height: 100%;
width: 100%;
position: relative; 
display: block; 
overflow: hidden; 
z-index: 1;
}

.product-card {
border: 1.5px solid #ddd;
border-radius: 8px;
overflow: hidden;
width: 345px;
}

.product-link {
text-decoration: none;
color: inherit;
display: block;
}

.owner-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(128, 128, 128, 0.7);
color: white;
display: flex;
justify-content: center;
align-items: center;
font-size: 18px;
text-align: center;
}

.browse-service-info {
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 15px;
margin-bottom: auto;
height: 40px;
}

.browse-service-name {
flex-grow: 1;
margin-right: 5px;
font-size: 1.2rem;
font-weight: normal;
overflow: hidden;   
text-overflow: ellipsis;
white-space: nowrap;
}

.browse-service-price {
white-space: nowrap;
font-size: 1.1 rem;
font-weight: normal;
}

.browse-service-details {
padding: 0 7px 10px 15px;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: auto;
}

.browse-user-info {
display: flex;
align-items: center;
}

.browse-profile-picture {
width: 30px;
height: 30px;
border-radius: 50%;
margin-right: 10px;
display: flex;
justify-content: center;
align-items: center;
font-size: 14px;
color: white;
text-transform: uppercase;
overflow: hidden;
background-color: #ccc;
}

.browse-profile-picture img {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 50%;
}

.user-name {
font-size: 1.1rem;
margin-right: 10px;
max-width: 180px;           
white-space: nowrap;        
overflow: hidden;           
text-overflow: ellipsis;    
}

.service-area {
font-style: italic;
color: #8d8d8d;
margin: 0;
padding: 0 5px 0 10px;
max-width: 260px;           
white-space: nowrap;        
overflow: hidden;           
text-overflow: ellipsis;    
}

.pagination {
margin-top: 20px;
display: flex;
justify-content: left;
margin-bottom: 20px; 
}

.pagination button {
margin: 0 5px;
padding: 5px 10px;
background-color: #f4f4f4;
border: 1px solid #ddd;
border-radius: 3px;
cursor: pointer;
color: black;
}

.product-section {
overflow-y: auto;
}

@media (max-width: 768px) {
.browse-container {
flex-direction: column;
overflow-y: auto;
}

.product-container {  
grid-template-columns: 1fr;
width: 100%; 
gap: 10px;
padding: 10px;
}

.browse-main {
padding: 5px;
}

.filter-sidebar {
display: flex;
flex-direction: row;
align-items: center;
padding: 10px;
height: auto;
overflow-x: auto;
width: auto;
}

.filter-sidebar.highlight {
background-color: #ffbaba; 
}

.filter-sidebar input,
.filter-sidebar .availability-filter,
.filter-sidebar .category-filter,
.filter-sidebar .price-filter {
width: auto;
margin-right: 10px;
margin-bottom: 0;
flex-shrink: 0;
font-size: 1rem;
}

.availability-filter,
.price-filter,
.category-filter {
display: flex;
flex-direction: row;
align-items: center;
margin-top: 0px;
height: 2.5rem;
padding: 0 0.5rem;
border: 1px solid #ddd; 
border-radius: 12px;
background-color: #fff; 
}

.availability-filter h3,
.price-filter h3,
.category-filter h3 {
margin: 0 5px 0 0;
font-size: 1rem;
font-weight: 500;
}

.price-option,
.category-option {
font-weight: 500;
margin: 0 5px 0 0;
}

.availability-filter label,
.price-option label,
.category-option label {
margin-bottom: 0px;
font-weight: 400;
margin-right: 3px;
margin-top: 4px;
}

.toggle-map-btn {
display: block;
width: auto; 
margin: 6px 6px 6px 6px;
color: rgb(0, 0, 0);
border: 2px solid rgb(255, 0, 0);
background-color: white;
text-align: center;
height: 50px;
display: flex;
position: relative;
justify-content: center;
align-items: center;
}

.toggle-map-btn img {
width: 100%;  
height: 100%;   
object-fit: cover; 
position: absolute; 
top: 0;
left: 0;
z-index: 1; 
opacity: 0.3;
}

.button-text {
color: rgb(0, 0, 0);
position: relative;  
font-size: 1.1rem;   
font-weight: 600;  
z-index: 2;          
text-align: center;
}

.toggle-map-btn.map-active {
background-color: rgb(255, 0, 0); 
color: rgb(255, 255, 255); 
border: 1px solid rgb(255, 0, 0);
}

.product-card {
width: 100%;
}

.service-image-container {
padding-top: 56.25%; 
margin-bottom: 5px;
min-height: 190px !important;
max-height: 700px;
}

.browse-service-info,
.browse-service-details {
padding: 5px 0 0 0;
}

.browse-service-name {
margin: 5px 0 0 10px;
font-size: 1.15rem;
max-width: 255px;
white-space: nowrap;
}

.browse-service-price {
white-space: nowrap;
font-weight: 400;
margin: 5px 5px 0 0;
font-size: 1rem;
}

.user-name {
font-size: 15px;
margin-bottom: 10px;
max-width: 200px;           
white-space: nowrap;        
overflow: hidden;           
text-overflow: ellipsis;    
}

.service-area {
font-style: italic;
font-size: 1rem;
color: #8d8d8d;
margin: 0 0 8px 0;
max-width: 160px;           
white-space: nowrap;        
overflow: hidden;           
text-overflow: ellipsis;    
}

.browse-profile-picture {
width: 30px;
height: 30px;
margin-left: 10px;
margin-bottom: 8px;
}

.browse-service-image-container {
min-height: 190px;
}
}

/* 

Products

*/

.products-page {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 5%;
  box-sizing: border-box;
}

.products-content-wrapper {
width: 100%;
display: flex;
flex-direction: row;
gap: 20px; 
margin-bottom: 20px;
}


.products-main-content {
font-size: 1.2rem;
flex: 1;
margin-right: 20px;
max-width: 1020px;
min-width: 0;
}

.products-sidebar {
flex-shrink: 0; 
}

.products-service-name {
font-size: 24px;
margin-bottom: 20px;
}

.service-image-container {
width: 100%;
max-height: 580px;
overflow: hidden;
margin-bottom: 20px;
position: relative;
padding-top: 60%; 
margin: 0;
}

.service-image {
width: 100%;
height: 100%;
object-fit: contain;
}

.image-navigation {
position: absolute;
top: 44%;
left: 0;
right: 0;
display: flex;
justify-content: space-between;
}

.products-nav-button.prev {
position: absolute;
left: 1px; 
}

.products-nav-button.next {
position: absolute;
right: 1px; 
}

.products-nav-button {
background-color: rgba(0, 0, 0, 0.5);
color: white;
border: none;
width: 40px;
height: 40px; 
padding: 10px;
cursor: pointer;
margin-bottom: 10px;
font-size: 17px;
}

.products-image-indicators {
display: flex;
justify-content: center;
margin-top: 0px;
}

.products-indicator-dot {
width: 8px;
height: 8px;
border-radius: 50%;
background-color: #ccc;
margin: 0 5px;
cursor: pointer;
z-index: 0;
transition: background-color 0.3s ease;
}

.products-indicator-dot.active {
background-color: #333;
}

.products-service-info {
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 10px;
}

.products-service-description {
font-size: 1.2rem;
}

.products-service-info p {
margin: 0;
}

.products-service-price {
font-size: 20px;
font-weight: 600;
}

.products-service-area {
font-size: 18px;
font-weight: 500;
font-style: italic;
padding-bottom: 0px;
}

.products-service-availability {
font-weight: 600;
}

.products-service-category {
font-weight: 600;
margin: 10px 0;
}

.products-service-time {
font-weight: 600;
margin: 10px 0;
display: flex;
gap: 0.5rem;
}

.products-service-time p{
margin: 1px 0;
font-weight: 500;
color: #434343;
}

.products-author-profile {
border: 1px solid #d9d9d9;
border-radius: 4px;
padding: 15px;
margin-bottom: 20px;
font-size: 16px;
max-width: 1020px;
}

.products-author-profile p{
margin-left: 10px;
font-size: 1.1rem;
}

.products-user-info {
display: flex;
align-items: center;
margin-bottom: 10px;
}

.products-profile-picture {
width: 50px;
height: 50px;
border-radius: 50%; 
display: flex;
justify-content: center;
align-items: center;
margin-right: 5px;
margin-left: 10px;
overflow: hidden;
background-color: #f0f0f0;
}

.products-profile-picture img {
width: 100%;
height: 100%;
object-fit: cover;
display: block; 
border-radius: 50%; 
}

.profile-picture span {
color: white;
font-weight: 600;
}

.products-user-name {
margin-left: 10px;
font-size: 1.2rem;
}

.products-user-link {
text-decoration: none;
}

.products-sidebar-content {
border: 1px solid #d9d9d9;
border-radius: 4px;
padding: 15px;
min-width: 300px;
margin-top: 100px;
}

.products-sidebar-service-info {
margin-bottom: 20px; 
}

.products-sidebar-service-info h2 {
font-size: 20px;
font-weight: 600;
margin: 0 0 5px 0;
}

.products-sidebar-price {
font-size: 16px;
color: grey;
font-weight: 600;
margin: 0;
}

.products-sidebar-buttons {
display: flex;
flex-direction: column;
}

.products-chat-button,
.products-call-button,
.products-order-button {
font-size: 17px;
padding: 10px;
border: none;
border-radius: 10px;
background-color: #ff0000;
color: white;
cursor: pointer;
width: 100%;
max-height: 40px;
margin-bottom: 10px;
}

.products-chat-button {
display: flex;
align-items: center;
justify-content: center;
gap: 7px;
color: white;
background-color: rgb(0, 66, 37);
}

.products-chat-button svg, 
.products-call-button svg {
margin-top: 2px;
}

.products-call-button {
background-color: #1700ae;
display: flex;
align-items: center;
justify-content: center;
gap: 7px;
color: white;
}

.products-order-button {
background-color: #ff0000;
color: white;
}

.owner-message-row {
background-color: #e6f7ff;
border: 1px solid #91d5ff;
border-radius: 10px;
padding: 10px;
margin: 10px auto;
font-size: 1.2rem;
color: #0050b3;
display: flex;
padding-left: 20px;
justify-content: space-between;
align-items: center;
}

.owner-message-row p {
margin: 0;
}

.service-edit-button {
background-color: rgb(255, 255, 255);
color: #757575;
border: 2px solid #757575;
font-size: 1rem;
border-radius: 4px;
cursor: pointer;
width: 90px;
height: 30px;
}

.service-delete-button:hover {
background-color: rgb(255, 0, 0);
border: 1px solid red;
color: white;
}

.map-section {
margin: 20px 0;
max-width: 1020px;
height: 400px;
}

.mobile-map {
margin: 20px auto;
width: 100%;
height: 300px;
position: relative; 
z-index: 1; 
pointer-events: none; 
}

.mobile-map.active {
  pointer-events: auto;
}

@media (max-width: 768px) {
.products-content-wrapper {
display: flex;
flex-direction: column;
gap: 20px;
margin-bottom: 1rem;
}

.products-page {
flex-direction: column;
margin: 0 0 0 0;
padding: 0 20px;
}

.products-main-content {
font-size: 1rem;
margin-right: 0;
order: 1;
}

.products-service-area {
font-size: 14px;
}

.products-service-description {
font-size: 1.1rem;
}

.products-sidebar {
width: 100%;
position: relative;
}

.products-author-profile {
width: 100%;
order: 4;
margin-top: 1rem;
margin-bottom: 0;
}

.product-profile-picture {
width: 30px; 
height: 30px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
font-size: 14px;
color: white;
text-transform: uppercase;
overflow: hidden;
}

.products-sidebar-content {
width: 100%;
margin-top: 0;
min-width: none;
margin-bottom: 1rem;
}

.browse-profile-picture img, .product-profile-picture img {
width: 100%;
height: 100%;
object-fit: cover;
}

.map-section {
display: none;
}

.mobile-map {
order: 3;
}
}

@media (min-width: 769px) {
.mobile-map {
  display: none;
}

.map-section {
  display: flex;
}
}

/*

Chat

*/
.chat-page {
display: flex;
height: 92vh;
overflow: hidden;
width: 100%;
}

.chat-list {
min-width: 240px;
height: calc(100vh - var(--navbar-height));
border-right: 1px solid #ccc;
overflow-y: auto;
padding: 0px;
display: flex;
flex-direction: column;
}

.chat-list h2 {
margin: 0;
padding: 15px;
font-size: 1.1rem;
font-weight: 600;
color: rgb(0, 0, 0);
background-color: whitesmoke;
}

.chat-list-scroll-area {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.chat-list-items {
display: flex;
flex-direction: column;
padding: 0;
margin:0
}

.chat-profile-picture {
width: 45px; 
height: 45px; 
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
margin-right: 10px;
font-size: 13px; 
color: rgb(0, 0, 0); 
background-color: #e0e0e0;
}

.chat-profile-picture img {
width: 100%;
height: 100%;
border-radius: 50%;
}

.chat-list-item {
display: flex;
align-items: center;
padding: 15px;
border: 0.5px solid #ccc;
cursor: pointer;
margin: 0;
}

.chat-list-item.chat-selected {
background-color: #e6e6e6; 
}

.chat-list-item.chat-unread {
background-color: #c2e1ff;
}

.chat-unread-badge {
background-color: #ff0000;
color: white;
border-radius: 50%;
padding: 2px 6px;
font-size: 12px;
margin-left: 10px;
}

.chat-navbar-badge {
background-color: red;
color: white;
border-radius: 50%;
padding: 2px 6px;
font-size: 12px;
margin-left: 5px;
}

.chat-message {
margin-bottom: 10px;
max-width: 50%;
word-wrap: break-word;
border-radius: 15px;
}

.chat-message-list {
flex-grow: 1;
overflow-y: auto;
padding: 10px;
display: flex;
flex-direction: column;
}

.chat-message.chat-sent {
align-self: flex-end;
background-color: #f2f2f2;
padding:10px;
text-align: left;
border-radius: 25px 25px 0 25px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.chat-message.chat-received {
align-self: flex-start;
background-color: #dcf8c6;
border-radius: 25px 25px 25px 0; 
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-list-item-details {
display: flex;
flex-direction: column;
}

.chat-list-item.unread {
background-color: #d3e6ff;
font-weight: 600;
}

.unread-badge {
background-color: #ff0000;
color: white;
border-radius: 50%;
padding: 2px 6px;
font-size: 12px;
margin-left: 10px;
}

.chat-window {
width:  calc(100% - 220px);;
display: flex;
flex-direction: column;
max-height: 100%;
overflow: hidden;
}

.chat-title {
display: flex;
flex-direction: column;
padding-left: 10px, 10px;
border-bottom: 1px solid #ccc;
}

.chat-title-info {
display: flex;
align-items: center;
flex-direction: row;
border-bottom: solid 0.5px lightgrey;
background-color: papayawhip;
}

.chat-title-info h2{
font-weight: 400;
font-size: 1.25rem;
flex-grow: 1;
align-items: center;
color: #003f8d;
margin: 0;
padding-left: 20px;
}

.chat-title-info a{
overflow-y: auto;
}

.chat-title-info p{
padding-right: 20px;
font-size: 1.20rem;
}

.chat-title-row {
display: flex;
justify-content: space-between;
}

.chat-title-divider {
margin-top: 10px;
border: 0;
border-top: 1px solid #ccc;
}

.booking-button-chat {
font-size: 15px;
border: none;
border-radius: 10px;
background-color: #ff0000;
color: white;
cursor: pointer;
width: auto;
margin: 15px 15px 0 0;
min-height: 30px;
min-width: 120px;
max-height: 35px;
}

.chat-banner {
color: rgb(179, 179, 179);
font-size: 15px;
margin: 5px auto; 
display: flex;
justify-content: center; 
align-items: center; 
width: 100%; 
}

.chat-message {
margin-bottom: 10px;
max-width: 60%; 
padding: 10px;
border-radius: 20px; 
word-wrap: break-word;
clear: both;
}

.chat-timestamp {
font-size: 0.7em;
color: #898989;
display: block;
margin-top: 2px;
}

.message-input {
display: flex;
padding: 10px;
background-color: #fff;
border-top: 1px solid #ccc;
margin-bottom: 0;
}

.message-input input {
flex-grow: 1;
font-size: 1rem;
padding: 10px;
border: 1px solid #ccc;
border-radius: 10px;
margin-right: 10px;
}

.submit-send {
padding: 10px 20px;
border: none;
border-left: none;
border-radius: 10px;
background-color: #ff4500;
color: white;
cursor: pointer;
max-width: 150px;
}

.no-conversation-selected {
display: flex;
justify-content: center;
align-items: center;
height: 100vh; 
text-align: center;
font-size: 1.5rem; 
color: #555; 
width: 100%; 
}

.error-container {
padding: 10px;
z-index: 11;
}

.chat-error {
color: red;
text-align: center;
margin-top: 8px;
margin-bottom: 8px;
}

.chat-page {
  display: flex;
  height: 92vh;
  overflow: hidden;
  width: 100%;
  }
  
  .chat-list {
  min-width: 240px;
  height: 100%;
  border-right: 1px solid #ccc;
  overflow-y: auto;
  padding: 0px;
  }
  
  .chat-list h2 {
  margin: 0;
  padding: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  background-color: whitesmoke;
  }
  
  .chat-list-items {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  }
  
  .chat-profile-picture {
  width: 45px; 
  height: 45px; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 13px; 
  color: rgb(0, 0, 0); 
  background-color: #e0e0e0;
  }
  
  .chat-profile-picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  }
  
  .chat-list-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 0.5px solid #ccc;
  cursor: pointer;
  margin: 0;
  }
  
  .chat-list-item.chat-selected {
  background-color: #e6e6e6; 
  }
  
  .chat-list-item.chat-unread {
  background-color: #c2e1ff;
  }
  
  .chat-unread-badge {
  background-color: #ff0000;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 10px;
  }
  
  .chat-navbar-badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 5px;
  }
  
  .chat-message {
  margin-bottom: 10px;
  max-width: 50%;
  word-wrap: break-word;
  border-radius: 15px;
  }
  
  .chat-message-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  }
  
  .chat-message.chat-sent {
  align-self: flex-end;
  background-color: #f2f2f2;
  padding:10px;
  text-align: left;
  border-radius: 25px 25px 0 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  .chat-message.chat-received {
  align-self: flex-start;
  background-color: #dcf8c6;
  border-radius: 25px 25px 25px 0; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .chat-list-item-details {
  display: flex;
  flex-direction: column;
  }
  
  .chat-list-item.unread {
  background-color: #d3e6ff;
  font-weight: 600;
  }
  
  .unread-badge {
  background-color: #ff0000;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 10px;
  }
  
  .chat-window {
  width:  calc(100% - 220px);;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  }
  
  .chat-title {
  display: flex;
  flex-direction: column;
  padding-left: 10px, 10px;
  border-bottom: 1px solid #ccc;
  }
  
  .chat-title-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: solid 0.5px lightgrey;
  background-color: papayawhip;
  }
  
  .chat-title-info h2{
  font-weight: 400;
  font-size: 1.25rem;
  flex-grow: 1;
  align-items: center;
  color: #003f8d;
  margin: 0;
  padding-left: 20px;
  }
  
  .chat-title-info a{
  overflow-y: auto;
  }
  
  .chat-title-info p{
  padding-right: 20px;
  font-size: 1.20rem;
  }
  
  .chat-title-row {
  display: flex;
  justify-content: space-between;
  }
  
  .chat-title-divider {
  margin-top: 10px;
  border: 0;
  border-top: 1px solid #ccc;
  }
  
  .booking-button-chat {
  font-size: 15px;
  border: none;
  border-radius: 10px;
  background-color: #ff0000;
  color: white;
  cursor: pointer;
  width: auto;
  margin: 15px 15px 0 0;
  min-height: 30px;
  min-width: 120px;
  max-height: 35px;
  }
  
  .chat-banner {
  color: rgb(179, 179, 179);
  font-size: 15px;
  margin: 5px auto; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  }
  
  .chat-message {
  margin-bottom: 10px;
  max-width: 60%; 
  padding: 10px;
  border-radius: 20px; 
  word-wrap: break-word;
  clear: both;
  }
  
  .chat-timestamp {
  font-size: 0.7em;
  color: #898989;
  display: block;
  margin-top: 2px;
  }
  
  .message-input {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  margin-bottom: 0;
  }
  
  .message-input input {
  flex-grow: 1;
  font-size: 1rem;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 10px;
  }
  
  .submit-send {
  padding: 10px 20px;
  border: none;
  border-left: none;
  border-radius: 10px;
  background-color: #ff4500;
  color: white;
  cursor: pointer;
  max-width: 150px;
  }
  
  .no-conversation-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;
  font-size: 1.5rem; 
  color: #555; 
  width: 100%; 
  }
  
  .error-container {
  padding: 10px;
  z-index: 11;
  }
  
  .chat-error {
  color: red;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
  }  

@media (max-width: 768px) {
.chat-window {
flex-grow: 1;
display: flex;
overflow: hidden;
flex-direction: column;
width: 100%;
position: relative;
padding-bottom: 60px;
margin-top: 70px;
}

.mobile-chat-header {
position: sticky;
top: 70px;
z-index: 10;
background-color: white;
}

.chat-list {
min-width: 100%;
height: auto;
border-right: none;
}

.chat-title-info {
position: sticky;
top: 0;
width: 100%;
z-index: 10;
border-bottom: 1px solid #ccc;
display: flex;
align-items: center;
justify-content: space-between;
}

.chat-title-info h2 {
margin: 0;
font-size: 1.2rem;
flex-grow: 1;
margin-left: auto;
}

.chat-title-info p {
font-size: 1.10rem;
}

.booking-button-chat {
margin-left: 10px;
padding: 5px 5px;
font-size: 14px;
}

.back-to-list {
display: block;
width: 100%;
padding: 15px;
text-align: center;
background-color: whitesmoke;
color: rgb(71, 71, 71);
border: 1px solid #ccc;
cursor: pointer;
position: sticky;
top: 0; 
z-index: 10; 
font-size: 1rem;
}

.chat-page-mobile {
display: flex;
flex-direction: column;
height: calc(var(--real-vh, 1vh) * 100); 
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
overflow: hidden;
}

.chat-message-list {
flex-grow: 1;
overflow-y: auto;
margin: auto 5px;
-webkit-overflow-scrolling: touch;
}

.message-input {
position: fixed;
bottom: 0;
left: 0;
right: 0;
background-color: white;
padding: 5px;
border-top: 1px solid #ccc;
z-index: 10;
display: flex;
height: 50px;
}

.error-message-chat {
color: red;
font-size: 1rem;
margin-bottom: 75%;
}
}

@media (min-width: 769px) {
.chat-list {
min-width: 240px;
}

.chat-window {
width: calc(100% - 240px);
}
}

/* 

Payment.css

*/

.payment-container-wrapper {
display: flex;
align-items: center;
justify-content: center;
}

.payment-container {
max-width: 800px; 
min-width: 700px;
margin: 50px auto;
padding: 2rem;
background-color: #ffffff;
border-radius: 8px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.payment-form {
display: flex;
flex-direction: column;
}

#payment-element {
margin-bottom: 20px;
}

#submit {
padding: 10px 15px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
font-size: 1.2rem;
cursor: pointer;
transition: background-color 0.3s ease;
}

#submit:disabled {
background-color: #cccccc;
cursor: not-allowed;
}

#payment-message {
margin-top: 20px;
color: red;
text-align: center;
}

.payment-container h2 {
text-align: center;
margin-bottom: 20px;
}

.payment-form #payment-element {
margin-bottom: 24px;
}

.payment-form button {
padding: 12px;
background-color: #5469d4;
color: #ffffff;
border: none;
border-radius: 4px;
cursor: pointer;
font-size: 16px;
font-weight: 600;
transition: background-color 0.3s ease;
width: 100%;
}

.payment-form button:disabled {
background-color: #cccccc;
cursor: not-allowed;
}

.payment-form button:hover:not(:disabled) {
background-color: #4255a4;
}

#payment-message {
color: rgb(105, 115, 134);
font-size: 16px;
line-height: 20px;
padding-top: 12px;
text-align: center;
}

.spinner,
.spinner:before,
.spinner:after {
border-radius: 50%;
}

.spinner {
color: #ffffff;
font-size: 22px;
text-indent: -99999px;
margin: 0px auto;
position: relative;
width: 20px;
height: 20px;
box-shadow: inset 0 0 0 2px;
transform: translateZ(0);
}

.spinner:before,
.spinner:after {
position: absolute;
content: '';
}

.spinner:before {
width: 10.4px;
height: 20.4px;
background: #5469d4;
border-radius: 20.4px 0 0 20.4px;
top: -0.2px;
left: -0.2px;
transform-origin: 10.4px 10.2px;
animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
width: 10.4px;
height: 10.2px;
background: #5469d4;
border-radius: 0 10.2px 10.2px 0;
top: -0.1px;
left: 10.2px;
transform-origin: 0px 10.2px;
animation: loading 2s infinite ease;
}

@keyframes loading {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}

/*

MyOrders.css 

*/

.my-order-summary {
width: 100%; 
margin: 0;
padding: 1px;
box-sizing: border-box;
}

.my-orders-page h1 {
text-align: center;
margin-bottom: 20px;
}

.orders-container {
display: flex;
flex-direction: column;
gap: 15px;
}

.order-info {
display: flex;
flex-direction: column;
gap: 5px;
}

/*

Account details page.css 

*/

.my-account-page {
width: 90%;
padding: 20px;
background-color: #f9f9f9;
border-radius: 8px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
margin: 20px auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}

.my-account-page h2 {
font-size: 1.7rem;
color: #333;
justify-content: center; 
font-weight: 600;
margin-bottom: 40px;
text-align: center;
}

.account-info {
display: flex;
align-items: center;
margin: 0 auto;
}

.account-info strong {
font-size: 1.2rem;
color: #555;
margin-right: 10px; 
font-weight: 600;
}

.account-info p {
font-size: 1.2rem;
color: #777;
margin: 0; 
}

.change-email-section {
margin-bottom: 20px;
padding: 15px;
background-color: #fff;
border-radius: 8px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.change-email-section h3 {
font-size: 1.2rem;
color: #333;
font-weight: 600;
margin: 10px;
width: auto;

}

.change-email-section input[type="email"] {
width: 95%;
padding: 10px;
font-size: 1rem;
margin-bottom: 10px;
border: 1px solid #ccc;
border-radius: 4px;
}

.change-email-section button {
padding: 10px 20px;
font-size: 1rem;
color: white;
background-color: #007bff;
border: none;
border-radius: 4px;
cursor: pointer;
margin-right: 10px;
}

.change-email-section button:hover,
.delete-account-button:hover {
background-color: #0056b3;
}

.delete-account-button {
background-color: #dc3545;
}

.delete-account-button:hover {
background-color: #c82333;
}

.success-message {
color: green;
font-size: 0.9rem;
margin-top: 10px;
}

/*

Submit review

*/
.submit-review {
min-height: 600px;
margin: 0 1rem;
padding: 20px;
background-color: #ffffff;
border-radius: 8px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
line-height: 1.6;
box-sizing: border-box;
}

.submit-review h2 {
font-size: 1.5rem;
color: #333333;
text-align: center;
margin-bottom: 20px;
}

.submit-review-rating {
display: flex;
justify-content: flex-start;
margin-bottom: 20px;
}

.submit-review .error-message {
color: #ff0000;
padding: 10px;
margin-bottom: 15px;
text-align: center;
font-size: 1rem;
}

.submit-review form {
display: flex;
flex-direction: column;
}

.submit-review-rating-label {
font-size: 1.20rem;
margin-right: 10px;
margin-top: 5px;
color: #333333;
}

.submit-review .rating button {
background: none;
border: none;
font-size: 1.6rem;
cursor: pointer;
margin-top: 1px;
transition: color 0.3s ease;
}

.submit-review-comment-label {
font-size: 1.2rem;
color: #333333;
margin-bottom: 5px;
display: block;
}

.submit-review-comment-textarea {
width: 100%;
height: 320px;
padding: 10px;
font-size: 1rem;
border: 1px solid #cccccc;
border-radius: 4px;
resize: none; 
box-sizing: border-box; 
}

.submit-review-submit-button[type="submit"] {
padding: 10px 15px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
font-size: 1rem;
cursor: pointer;
transition: background-color 0.3s ease;
}

.submit-review-submit-button:disabled {
background-color: #cccccc; 
color: #666666; 
cursor: not-allowed; 
opacity: 0.7; 
}

.submit-review-stars {
display: flex;
gap: 8px; 
}

.submit-review-star {
background: none;
border: none;
font-size: 1.5rem;
cursor: pointer;
color: #cccccc; /* Default star color */
transition: color 0.2s ease-in-out;
}

.submit-review__star:hover,
.submit-review__star--filled {
color: #ffcc00; /* Filled star color */
}

.submit-review-terms {
margin: 10px 0;
font-size: 0.9rem;
display: flex;
align-items: center;
}

.submit-review-terms label {
margin-left: 15px;
color: #333;
font-size: 1rem;
}

.submit-review-terms input {
margin: 0;
width: 1.3rem;
height: 1.3rem;
}

/*

Order summary 
confirmation

*/


.order-confirmation {
max-width: 32rem;
margin: 0 auto;
padding: 1.5rem;
background-color: whitesmoke;
border-radius: 0.5rem;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.order-confirmation h2 {
font-size: 1.875rem;
font-weight: bold;
margin: 1rem 0 1.5rem 0;
color: #1f2937;
}

.order-confirmation p {
font-size: 1.1rem;
margin: 0.5rem;
color: #4b5563;
}

.order-details {
background-color: #ffffff;
border-radius: 0.5rem;
padding: 1.2rem;
margin: 1.5rem 0 1.5rem 0;
min-height: 150px;
}

.order-header {
display: grid;
grid-template-columns: 1fr 120px 100px;
gap: 1rem;
margin: 0.5rem 0 1rem 0;
font-weight: 600;
}

.order-header span:nth-child(1) {
text-align: left;
}

.order-header span:nth-child(2) {
text-align: center;
}

.order-header span:nth-child(3) {
text-align: right;
}

.order-item {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 1rem;
}

.unit-control {
display: flex;
align-items: center;
margin-left: 0;
}

.unit-button {
background-color: #ff3300;
border: none;
color: white;
border-radius: 50%;
padding: 0.22rem;
transition: background-color 0.2s;
}

.unit-button:hover {
background-color: #ab0000;
}

.unit-control span {
margin: 0 0.75rem;
}

.order-total {
display: flex;
justify-content: space-between;
font-weight: bold;
font-size: 1.125rem;
margin: 2rem 0 0 0;
}

.booking-notes {
display: flex;
}

.booking-notes li{
font-size: 1.1rem;
margin-bottom: 1rem;
color: rgb(56, 56, 56);
}

.confirm-button {
width: 100%;
background-color: rgb(0, 66, 37);
cursor: pointer;
color: white;
padding: 0.75rem;
min-height: 50px;
margin-bottom: 1rem;
border-radius: 0.5rem;
font-weight: bold;
font-size: 1rem;
transition: background-color 0.2s;
}

.loading, .error, .not-found {
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
}

.order-item span{
font-size: 1.1rem;
}

.error {
color: #ef4444;
}  

.order-confirmation-container {
width: auto;
min-height: 600px;
margin: auto;
padding: 20px;
background-color: papayawhip;
border-radius: 8px;
text-align: center;
display: flex;           
flex-direction: column;  
justify-content: center; 
align-items: center;     
}

.order-confirmation-heading {
font-size: 1.7rem;
color: #333;
margin-bottom: 20px;
}

.order-confirmation-message {
font-size: 1.2rem;
color: #555;
margin-bottom: 20px;
}

.order-confirmation-next-steps {
font-size: 1rem;
color: #777;
margin-bottom: 40px;
}

.order-confirmation-button {
padding: 10px 20px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
font-size: 1rem;
cursor: pointer;
text-decoration: none; 
}

.selected-time-slot {
padding: 0.5rem;
background-color: #e8f1ff;
border-radius: 0.5rem;
}

.time-slot-info {
display: flex;
align-items: center;
justify-content: space-between; 
}

.time-slot-text {
font-size: 1.125rem;
font-weight: 500;
color: #0f172a;
margin-bottom: 0.25rem;
}

.time-slot-date {
color: #64748b;
font-size: 0.875rem;
}

.time-warning {
display: flex;
align-items: center;
gap: 0.5rem;
margin-top: 0.75rem;
padding: 0.75rem;
background-color: #fff7ed;
border-radius: 0.375rem;
color: #9a3412;
}

.warning-icon {
color: #ea580c;
}

.booking-remarks p{
color: #676767;
font-size: 1rem;
margin: 1rem;
font-style: italic;
}

.change-time-button {
align-items: center;
display: flex;
font-size: .9rem;
gap: .25rem;
margin: .5rem 0;
text-decoration: none;
background-color: #ff4500;
border: none;
color: white;
border-radius: 10px;
padding: 0.4rem;
}

@media (max-width: 768px) {
.time-slot-info {
display: unset;
}

.order-header span:nth-child(2) {
padding-left: 60%;
}

.order-item span{
max-width: 30%;
}

.payment-container {
margin: 0;
min-width: 300px;
width: 100%;
height: 100%;
}
}

/* 

Report user 

*/

.report-user-container {
  margin: 0 auto;
  min-width: 800px;
  padding: 24px;
  background-color: whitesmoke;
}

.report-user-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.report-user-header {
  padding: 6px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.report-subject-user {
  color: red;
  font-weight: 700;
}

.report-user-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.report-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.report-form-label {
  font-weight: 500;
}

.report-form-select {
  padding: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
  border-radius: 4px;
  width: 100%;
}

.report-form-textarea {
  padding: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
  border-radius: 4px;
  min-height: 150px;
  width: 100%;
  resize: vertical;
}

.report-form-checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.report-submit-button {
  background-color: #dc2626;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.report-submit-button:hover {
  background-color: #b91c1c;
}

.report-submit-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.report-status-message {
  margin-top: 16px;
  padding: 16px;
  background-color: #e6f3ff;
  color: #0052cc;
  border-radius: 4px;
}

.report-redirect-message {
  margin-top: 16px;
  color: #4b5563;
}


@media (max-width: 768px) {
  .report-user-container {
  width: 100%;
  min-width: 0;
  margin: 0 auto;
  padding: 14px;
  }
  }